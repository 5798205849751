import axios from "axios";
import { URLS } from "./URLS";

export default async function getBalance(id_pa) {
  let data = {};
  await axios(`${URLS.krakenURL}saldo-na/saldo/${id_pa}`)
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data = "error";
    });
  //console.log(data);
  return data;
}
