import { useState, useEffect } from "react";
import Store from "../../Stores/Store";
import style from "./PaymentWindow.module.scss";
import Heading from "../Typography/Heading/Heading";
import Text from "../Typography/Text/Text";
import Input from "../Input/Input";
import Button from "../Button/Button";
import terminalPay from "../../Static/images/terminalPay.png";
import close from "../../Static/images/close.svg";
import sbp from "../../Static/images/sbp.png";
import done from "../../Static/images/done.svg";
import error from "../../Static/images/error.svg";
import toMainImg from "../../Static/images/toMainImg.svg";
import qrPay from "../../Static/images/qrPay.png";
import {
  closeIframeMessage,
  paymentMessage,
} from "../../Utils/iframeMessenger";
import getQrForPayment from "../../API/getQrCodeForPayment";

function PaymentWindow() {
  const [mailInputValue, setMailInputValue] = useState("");
  const [modalQrPayActive, setModalQrPayActive] = useState(false);
  const [modalTerminalPayActive, setModalTerminalPayActive] = useState(false);
  const [modalPayDoneActive, setModalPayDoneActive] = useState(false);
  const [modalPayErrorActive, setModalPayErrorActive] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [sendReceipt, setSendReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrImage, setQrImage] = useState();

  window.onmessage = (e) => {
    if (e.data && !e.data.type) {
      const message = JSON.parse(e.data);
      if (message.command === "payStatus") {
        if (message.data.status) {
          setModalPayDoneActive(true);
        } else {
          setModalPayErrorActive(true);
        }
      }
    }
  };

  useEffect(() => {
    function HARDCODEtypeOfService() {
      if (
        Store.payment.accounts.id_org === 1 ||
        Store.payment.accounts.id_org === "1"
      ) {
        Store.payment.setService("Электроснабжение");
      } else if (
        Store.payment.accounts.id_org === 2 ||
        Store.payment.accounts.id_org === "2"
      ) {
        Store.payment.setService("Водоснабжение, водоотведение");
      }
    }
    HARDCODEtypeOfService();
  }, []);

  function mailChange(e) {
    setMailInputValue(e.target.value);
  }

  function terminalPayment() {
    setModalTerminalPayActive(true);
    paymentMessage(
      Store.payment.accounts.id_PA,
      Store.payment.amount,
      Store.payment.service,
      Store.payment.email
    );
  }

  async function qrPayment() {
    if (sendReceipt) {
      Store.payment.setEmail(mailInputValue);
    } else {
      Store.payment.setEmail("");
    }
    setLoading(true);
    const data = await getQrForPayment(
      Store.payment.amount,
      Store.payment.accounts.id_PA,
      Store.payment.email,
      Store.payment.service
    );
    if (data) {
      setModalQrPayActive(true);
      setQrImage(data.data.data.image.content);
    }
    setLoading(false);
  }

  //function retryTerminalPay() {
  //  terminalPayment();
  //  setModalPayErrorActive(false);
  //}

  function closePaymentWindow() {
    Store.payment.setPaymentWindowActive(false);
  }

  function backToMainPage() {
    closeIframeMessage();
  }

  function pay() {
    if (sendReceipt) {
      if (mailInputValue) {
        Store.payment.setEmail(mailInputValue);
      } else {
        Store.payment.setEmail("");
      }
    } else {
      Store.payment.setEmail("");
    }
    if (paymentMethod === "terminal") {
      terminalPayment();
    } else if (paymentMethod === "qr") {
      qrPayment();
    }
  }

  function closeTerminalPaymentModals() {
    setModalTerminalPayActive(false);
    setModalPayDoneActive(false);
    setModalPayErrorActive(false);
  }

  return (
    <div className={style.wrap}>
      <div className={style.content}>
        <div className={style.backWrap}>
          <div className={style.toMain} onClick={() => closeIframeMessage()}>
            <img src={toMainImg} alt="" />
            <Text size="medium">Главная</Text>
          </div>
          <div className={style.devider}>/</div>
          <div className={style.back} onClick={() => closePaymentWindow()}>
            <Text size="medium">Назад</Text>
          </div>
        </div>
        <Heading level={"h1"}>Выберите способ оплаты</Heading>
        <div className={style.cards}>
          <div
            className={
              paymentMethod === "terminal"
                ? `${style.card} ${style.active}`
                : `${style.card}`
            }
            onClick={() => {
              setPaymentMethod("terminal");
            }}
          >
            <img src={terminalPay} alt="" />
            <div className={style.text}>Через терминал</div>
          </div>
          <div
            className={
              paymentMethod === "qr"
                ? `${style.card} ${style.active}`
                : `${style.card}`
            }
            onClick={() => setPaymentMethod("qr")}
          >
            <img src={qrPay} alt="" />
            <div className={style.text}>По QR-коду</div>
          </div>
        </div>
        <div className={style.fields}>
          <div className={style.sendReceipt}>
            <div className={style.field} onClick={() => setSendReceipt(true)}>
              <div
                className={
                  sendReceipt
                    ? `${style.checkBox} ${style.active}`
                    : `${style.checkBox}`
                }
              ></div>
              <div className={style.title}>
                <Heading level="h5">
                  Прислать квитанцию об оплате на E-mail
                </Heading>
              </div>
            </div>
            {sendReceipt && (
              <Input
                changeHandler={mailChange}
                placeholder={"Введите e-mail"}
                autofocus={true}
              />
            )}
          </div>
          <div className={style.sendReceipt}>
            <div className={style.field} onClick={() => setSendReceipt(false)}>
              <div
                className={
                  !sendReceipt
                    ? `${style.checkBox} ${style.active}`
                    : `${style.checkBox}`
                }
              ></div>
              <div className={style.title}>
                <Heading level="h5">Отказаться от квитанции</Heading>
              </div>
            </div>
          </div>
        </div>
        {paymentMethod && (
          <div className={style.button}>
            <Button disabled={false} loading={loading} clickHandler={pay}>
              Продолжить
            </Button>
          </div>
        )}
      </div>
      {modalQrPayActive && (
        <div
          className={style.modalQrPay}
          onClick={() => setModalQrPayActive(false)}
        >
          <div
            className={style.modalQrPay__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.modalQrPay__wrap__close}
              onClick={() => setModalQrPayActive(false)}
            >
              <img src={close} alt="" />
            </div>
            <div className={style.modalQrPay__wrap__content}>
              <div className={style.modalQrPay__wrap__content__title}>
                <Heading level="h3">Система быстрых платежей</Heading>
              </div>
              <div className={style.modalQrPay__wrap__content__subtitle}>
                Наведите камеру смартфона для перехода в мобильное <br />
                приложение вашего банка для завершения оплаты
              </div>
              <div className={style.modalQrPay__wrap__content__sum}>
                <Heading level="h5">
                  Сумма к оплате {Store.payment.amount / 100} ₽
                </Heading>
              </div>
              <div className={style.modalQrPay__wrap__content__pay}>
                <div className={style.modalQrPay__wrap__content__pay__qr}>
                  <img src={`data:image/jpeg;base64,${qrImage}`} alt="" />
                </div>
                <div className={style.modalQrPay__wrap__content__pay__spb}>
                  <img src={sbp} alt="" />
                </div>
              </div>
              <div
                className={style.modalQrPay__wrap__content__btn}
                onClick={() => backToMainPage()}
              >
                <Text size="btnText">На главную</Text>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalTerminalPayActive && (
        <div className={style.modalTerminalPay}>
          <div
            className={style.modalTerminalPay__wrap}
            //onClick={(e) => e.stopPropagation()}
          >
            <div className={style.modalTerminalPay__wrap__content}>
              <div className={style.modalTerminalPay__wrap__content__title}>
                <Heading level="h3">Следуйте указаниям на терминале</Heading>
              </div>
              <div className={style.modalTerminalPay__wrap__content__sum}>
                <Heading level="h5">
                  Сумма к оплате {Store.payment.amount / 100} ₽
                </Heading>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalPayDoneActive && (
        <div
          className={style.modalPayStatus}
          onClick={() => closeTerminalPaymentModals()}
        >
          <div
            className={style.modalPayStatus__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.modalPayStatus__wrap__close}
              onClick={() => closeTerminalPaymentModals()}
            >
              <img src={close} alt="" />
            </div>
            <div className={style.modalPayStatus__wrap__content}>
              <div className={style.modalPayStatus__wrap__content__status}>
                <img src={done} alt="" />
              </div>
              <div className={style.modalPayStatus__wrap__content__title}>
                <Heading level="h3">Ваш платеж успешно прошел</Heading>
              </div>
              <div className={style.modalPayStatus__wrap__content__btns}>
                <div
                  className={style.modalPayStatus__wrap__content__btns__btn}
                  onClick={() => backToMainPage()}
                >
                  <Text size="btnText">На главную страницу</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalPayErrorActive && (
        <div
          className={style.modalPayStatus}
          onClick={() => closeTerminalPaymentModals()}
        >
          <div
            className={style.modalPayStatus__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.modalPayStatus__wrap__close}
              onClick={() => closeTerminalPaymentModals()}
            >
              <img src={close} alt="" />
            </div>
            <div className={style.modalPayStatus__wrap__content}>
              <div className={style.modalPayStatus__wrap__content__status}>
                <img src={error} alt="" />
              </div>
              <div className={style.modalPayStatus__wrap__content__title}>
                <Heading level="h3">Ошибка платежа</Heading>
              </div>
              <div className={style.modalPayStatus__wrap__content__subtitle}>
                <Text size="medium">
                  Возникла ошибка при обработке платежа. Попробуйте <br />
                  повторить платеж
                </Text>
              </div>
              <div className={style.modalPayStatus__wrap__content__btns}>
                <div
                  className={style.modalPayStatus__wrap__content__btns__btn}
                  onClick={() => closeTerminalPaymentModals()}
                >
                  <Text size="btnText">Повторить платеж</Text>
                </div>
                <div
                  className={`${style.modalPayStatus__wrap__content__btns__btn} ${style.sec}`}
                  onClick={() => backToMainPage()}
                >
                  <Text size="btnText">На главную страницу</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentWindow;
