import style from "./MethodOfReporting.module.scss";
import Text from "../Typography/Text/Text";
import Heading from "../Typography/Heading/Heading";
import lsSvg from "../../Static/images/ls.svg";
import addressSvg from "../../Static/images/address.svg";
import toMainImg from "../../Static/images/toMainImg.svg";
import { isBalance, isInfomat } from "../../API/URLS";
import { closeIframeMessage } from "../../Utils/iframeMessenger";

function MethodOfReporting({ setMethodOfReporting }) {
  function click(type) {
    setMethodOfReporting(type);
  }

  return (
    <div
      className={isInfomat ? `${style.wrap} ${style.infomat}` : `${style.wrap}`}
    >
      <div className={style.content}>
        {isInfomat && (
          <div className={style.backWrap}>
            <div className={style.toMain} onClick={() => closeIframeMessage()}>
              <img src={toMainImg} alt="" />
              <Text size="medium">Главная</Text>
            </div>
            <div className={style.devider}>/</div>
            <div className={style.back} onClick={() => closeIframeMessage()}>
              <Text size="medium">Назад</Text>
            </div>
          </div>
        )}
        <Heading level={"h1"}>
          {isBalance
            ? "Поиск лицевого счета"
            : "Выберите способ передачи показаний приборов учета"}
        </Heading>
        <div className={style.cards}>
          <div
            className={style.card}
            onClick={() => {
              click("ls");
            }}
          >
            <img src={lsSvg} alt="" />
            <div className={style.text}>По номеру лицевого счета</div>
          </div>
          <div
            className={style.card}
            onClick={() => {
              click("address");
            }}
          >
            <img src={addressSvg} alt="" />
            <div className={style.text}>По адресу проживания</div>
          </div>
        </div>
      </div>
      <div className={`${style.methodFooter} footer`}>
        <div className="footer__content">
          <div className="footer__content__hotline">
            <a href="tel:+78002000742">
              <Text size="small">Горячая линия</Text>
              <Text size="small">8-800-2000-742</Text>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MethodOfReporting;
