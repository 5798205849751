import { isInfomat } from "../API/URLS";

const url = getInfomatURL();

function getInfomatURL() {
  const paramUrl = new URL(document.location).searchParams.get("url");
  const paramQServerUrl = new URL(document.location).searchParams.get(
    "qServerUrl"
  );
  return `${paramUrl}&qServerUrl=${paramQServerUrl}`;
}

export function inputFocusMessage(command) {
  const message = {};
  if (command === "openNumKeyboard") {
    message.command = "openNumKeyboard";
  } else if (command === "openKeyboard") {
    message.command = "openKeyboard";
  } else if (command === "closeKeyboard") {
    message.command = "closeKeyboard";
  }
  if (isInfomat) {
    window.parent.postMessage(JSON.stringify(message), url);
  }
}

export function closeIframeMessage() {
  const message = {
    command: "close",
  };
  if (isInfomat) {
    window.parent.postMessage(JSON.stringify(message), url);
  }
}

export function resetTimerMessage() {
  const message = {
    command: "resetTimer",
  };
  if (isInfomat) {
    window.parent.postMessage(JSON.stringify(message), url);
  }
}

export function paymentMessage(id_pa, amount, service, email) {
  const message = {
    command: "pay",
    data: {
      id_pa: id_pa,
      email: email,
      amount: parseInt(amount),
      service: service,
    },
  };
  if (isInfomat) {
    window.parent.postMessage(JSON.stringify(message), url);
  }
}
