import React from "react";
import styles from "./Heading.module.scss";
import { isInfomat } from "../../../API/URLS";

export default function Heading({ level, children }) {
  if (level === "h1") {
    if (isInfomat) {
      return (
        <h1 className={`${styles.heading} ${styles.infomat}`}>{children}</h1>
      );
    } else {
      return <h1 className={styles.heading}>{children}</h1>;
    }
  } else if (level === "h2") {
    if (isInfomat) {
      return (
        <h2 className={`${styles.heading} ${styles.infomat}`}>{children}</h2>
      );
    } else {
      return <h2 className={styles.heading}>{children}</h2>;
    }
  } else if (level === "h3") {
    if (isInfomat) {
      return (
        <h3 className={`${styles.heading} ${styles.infomat}`}>{children}</h3>
      );
    } else {
      return <h3 className={styles.heading}>{children}</h3>;
    }
  } else if (level === "h4") {
    if (isInfomat) {
      return (
        <h4 className={`${styles.heading} ${styles.infomat}`}>{children}</h4>
      );
    } else {
      return <h4 className={styles.heading}>{children}</h4>;
    }
  } else if (level === "h5") {
    return <h5 className={`${styles.heading}`}>{children}</h5>;
  }
}
