import axios from "axios";
import { URLS } from "./URLS";

export default async function getQrForPayment(amount, id_pa, email, services) {
  let data = {};
  if (email) {
    data = await axios
      .post(
        `${URLS.krakenURL}acquire/sbp/reglink?amount=${amount}&id_pa=${id_pa}&services=${services}&qrTtl=60&mediaType=image/png&email=${email}&width=200&height=200`,
        {}
      )
      .catch(function (error) {});
  } else {
    data = await axios
      .post(
        `${URLS.krakenURL}acquire/sbp/reglink?amount=${amount}&id_pa=${id_pa}&services=${services}&qrTtl=60&mediaType=image/png&width=200&height=200`,
        {}
      )
      .catch(function (error) {});
  }
  return data;
}
