import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Store from "../../Stores/Store";
import style from "./BalanceWIndow.module.scss";
import Heading from "../Typography/Heading/Heading";
import Input from "../Input/Input";
import Text from "../Typography/Text/Text";
import toMainImg from "../../Static/images/toMainImg.svg";
import { ReactComponent as BalanceImage } from "../../Static/images/balance.svg";
import {
  closeIframeMessage,
  inputFocusMessage,
} from "../../Utils/iframeMessenger";
import { canPay } from "../../Utils/Utils";
import Button from "../Button/Button";

const BalanceWindow = observer(({ setBalanceWindowActive, balanceInfo }) => {
  const [modalEnterSumActive, setModalEnterSumActive] = useState(false);
  const [sumInputValue, setSumInputValue] = useState();
  const [saldo, setSaldo] = useState("");

  async function setBalance() {
    let balance = null;
    if (balanceInfo !== "error") {
      if (balanceInfo.amount1 <= 0) {
        balance = Math.abs(balanceInfo.amount1);
      } else {
        balance = "-" + balanceInfo.amount1;
      }
      setSaldo(parseFloat(balance));
      setSumInputValue(Math.abs(balance));
    } else {
      setSaldo(balance);
    }
  }

  useEffect(() => {
    setBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function HARDCODEtypeOfService() {
    if (
      Store.payment.accounts.id_org === 1 ||
      Store.payment.accounts.id_org === "1"
    ) {
      return "Электроэнергия";
    } else if (
      Store.payment.accounts.id_org === 2 ||
      Store.payment.accounts.id_org === "2"
    ) {
      return "Водоснабжение, водоотведение";
    }
  }

  function backToBalance() {
    setBalance();
    setModalEnterSumActive(false);
  }

  function toPaymentWindow() {
    if (sumInputValue > 0) {
      let sumValue = Math.ceil(parseFloat(sumInputValue).toFixed(3) * 100);
      Store.payment.setAmount(sumValue);
      Store.payment.setPaymentWindowActive(true);
    }
  }

  function sumInputChange(value) {
    let filteredValue;
    filteredValue = value
      .replace(/^[^\d]/g, "")
      .replace(/[^\d.]/g, "")
      .replace(/^([^.]*\.)|\./g, "$1");
    if (filteredValue.indexOf(".") !== -1) {
      filteredValue = filteredValue.substring(
        0,
        filteredValue.indexOf(".") + 3
      );
    }
    setSumInputValue(filteredValue);
  }

  function upBalance() {
    if (saldo < 0) {
      setSumInputValue(Math.abs(balanceInfo.amount1));
    } else {
      setSumInputValue("");
    }
    setModalEnterSumActive(true);
  }

  function clickBack() {
    closeIframeMessage();
  }

  function renderDescription() {
    let description = "";
    if (!canPay()) {
      description = "По данной услуге пополнение баланса невозможно";
    } else if (saldo === null) {
      description = "";
    } else {
      if (saldo > 0) {
        description = "переплата";
      } else if (saldo === 0) {
        description = "";
      } else if (saldo < 0) {
        description = "задолженность";
      }
    }
    return description;
  }

  const core_device0 = Store.payment.accountInfo.core_devices[0];

  return (
    <>
      <div className={style.balanceWindow}>
        <div
          className={style.balanceWindow__wrap}
          //onClick={(e) => e.stopPropagation()}
        >
          <div className={style.balanceWindow__wrap__back}>
            <div className={style.toMain} onClick={() => clickBack()}>
              <img src={toMainImg} alt="" />
              <Text size="medium">Главная</Text>
            </div>
            <div className={style.devider}>/</div>
            <div
              className={style.back}
              onClick={() => setBalanceWindowActive(false)}
            >
              <Text size="medium">Назад</Text>
            </div>
          </div>
          <div className={style.balanceWindow__wrap__title}>
            <Heading level="h3">
              Лицевой счет № {Store.payment.accountInfo.number}
            </Heading>
          </div>
          <div className={style.balanceWindow__wrap__content}>
            <div className={style.balanceWindow__wrap__content__address}>
              <Text size="medium">
                {core_device0.type_locality} {core_device0.locality}{" "}
                {core_device0.type_street} {core_device0.street}{" "}
                {core_device0.type_house} {core_device0.house}{" "}
                {core_device0.type_building} {core_device0.building}{" "}
                {core_device0.condos_number && "кв."}{" "}
                {core_device0.condos_number}
              </Text>
            </div>
            <div
              className={
                saldo !== null && saldo >= 0
                  ? `${style.balanceWindow__wrap__content__balance}`
                  : `${style.balanceWindow__wrap__content__balance} ${style.debt}`
              }
            >
              <div
                className={style.balanceWindow__wrap__content__balance__text}
              >
                <BalanceImage />
                <div className={style.balance}>
                  <Heading level="h5">
                    {saldo !== null ? "Текущий баланс" : "Баланс не найден"}
                  </Heading>
                  <div
                    className={
                      canPay()
                        ? `${style.description}`
                        : `${style.description} ${style.cantPay}`
                    }
                  >
                    <Text size="headerText">{renderDescription()}</Text>
                  </div>
                </div>
              </div>
              {saldo !== null && (
                <div
                  className={style.balanceWindow__wrap__content__balance__value}
                >
                  <Heading level="h4">{saldo} ₽</Heading>
                </div>
              )}
            </div>
            <div className={style.balanceWindow__wrap__content__button}>
              <Button disabled={!canPay()} clickHandler={upBalance}>
                Пополнить
              </Button>
            </div>
          </div>
        </div>
      </div>

      {modalEnterSumActive && (
        <div className={style.modalUpBalance}>
          <div
            className={style.modalUpBalance__wrap}
            //onClick={(e) => e.stopPropagation()}
          >
            <div className={style.modalUpBalance__wrap__back}>
              <div
                className={style.toMain}
                onClick={() => closeIframeMessage()}
              >
                <img src={toMainImg} alt="" />
                <Text size="medium">Главная</Text>
              </div>
              <div className={style.devider}>/</div>
              <div className={style.back} onClick={() => backToBalance()}>
                <Text size="medium">Назад</Text>
              </div>
            </div>

            <div className={style.modalUpBalance__wrap__title}>
              <Heading level="h3">
                Лицевой счет № {Store.payment.accountInfo.number}
              </Heading>
            </div>
            <div className={style.modalUpBalance__wrap__content}>
              <div className={style.modalUpBalance__wrap__content__item}>
                <div className={style.title}>Тип услуги</div>
                <Input
                  clickHandler={() => {}}
                  placeholder={HARDCODEtypeOfService()}
                  readOnly
                />
              </div>
              <div className={style.modalUpBalance__wrap__content__item}>
                <div className={style.title}>Сумма к оплате</div>
                <input
                  onChange={(e) => sumInputChange(e.target.value)}
                  value={sumInputValue}
                  className={style.sumInput}
                  autoFocus={true}
                  onFocus={() => inputFocusMessage("openNumKeyboard")}
                  onBlur={() => inputFocusMessage("closeKeyboard")}
                />
              </div>
              <button
                className={`${style.modalUpBalance__wrap__content__button} ${style.loading}`}
                onClick={() => toPaymentWindow()}
              >
                <Text size="btnText">Далее</Text>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
export default BalanceWindow;
