import { makeAutoObservable } from "mobx";

class payment {
  constructor() {
    makeAutoObservable(this);
  }

  accounts = null;
  accountInfo = null;
  service = null;
  amount = null;
  email = null;

  paymentWindowActive = false;

  setAccountInfo(accountInfo) {
    this.accountInfo = accountInfo;
  }

  setAccounts(accounts) {
    this.accounts = accounts;
  }

  setAmount(amount) {
    this.amount = amount;
  }

  setEmail(email) {
    this.email = email;
  }

  setService(service) {
    this.service = service;
  }

  resetPaymentInfo() {
    this.accounts = null;
    this.accountInfo = null;
    this.sum = null;
    this.email = null;
    this.service = null;
  }

  setPaymentWindowActive(status) {
    this.paymentWindowActive = status;
  }
}

const Store = {
  payment: new payment(),
};

export default Store;
