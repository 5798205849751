import axios from "axios";
import { URLS } from "./URLS";

export default async function getAccountInfo(id) {
  let data = {};

  await axios(` ${URLS.url}/${URLS.accountInfoURL}/${id}`)
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data = "error";
    });
  //console.log(data);
  return data;
}
