import Store from "../Stores/Store";

export function canPay() {
  if (
    Store.payment.accounts.id_org === "1" ||
    Store.payment.accounts.id_org === 1
  ) {
    return true;
  } else {
    return false;
  }
}
