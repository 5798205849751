import React from "react";
import styles from "./NotTime.module.scss";
import Text from "../Typography/Text/Text";
import Heading from "../Typography/Heading/Heading";
import man from "../../Static/images/man.png";
import toMainImg from "../../Static/images/toMainImg.svg";
import { isInfomat } from "../../API/URLS";
import { closeIframeMessage } from "../../Utils/iframeMessenger";

export default function NotTime() {
  return (
    <div
      className={
        isInfomat ? `${styles.wrap} ${styles.infomat}` : `${styles.wrap}`
      }
    >
      <div className={styles.body}>
        {isInfomat && (
          <div className={styles.backWrap}>
            <div className={styles.toMain} onClick={() => closeIframeMessage()}>
              <img src={toMainImg} alt="" />
              <Text size="medium" fw={500}>
                Главная
              </Text>
            </div>
            <div className={styles.devider}>/</div>
            <div
              className={styles.back}
              onClick={() => closeIframeMessage()}
            >
              <Text size="medium" fw={500}>
                Назад
              </Text>
            </div>
          </div>
        )}
        <div className={styles.image}>
          <img src={man} alt="" />
        </div>
        <div className={styles.title}>
          <Heading level="h1"> Уважаемые клиенты!</Heading>
        </div>
        <div className={styles.subtitle}>
          <Text size="large" fw={500}>
            Передача показаний возможна с 15 по 25 число месяца включительно
          </Text>
        </div>
      </div>
    </div>
  );
}
