import axios from "axios";

import { URLS } from "./URLS";

export default async function getAccounts(ls) {
  let data = [];
  await axios(` ${URLS.url}/${URLS.accountsURL}/${ls}`)
    .then((res) => {
      data = [...res.data];
    })
    .catch(function (error) {
      data.push({ error: "error" });
    });
  //console.log(data);
  return data;
}
