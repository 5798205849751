import axios from "axios";
import { URLS } from "./URLS";

export default async function getCondo(address_id, contact_number) {
  let data = [];
  await axios(
    `${URLS.url}/${URLS.condosURL}?address_id=${address_id}&contact_number=${contact_number}`
  )
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data.push({ contact_number: "error" });
    });
  return data;
}
