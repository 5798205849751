import { useRef, useState, useEffect } from "react";
import Store from "../../Stores/Store";
import reactStringReplace from "react-string-replace";
import MediaQuery from "react-responsive";
import styles from "./SearchByAddress.module.scss";
import Text from "../Typography/Text/Text";
import Heading from "../Typography/Heading/Heading";
import Input from "../Input/Input";
import getLocalities from "../../API/getLocalities";
import getStreets from "../../API/getStreets";
import getHouses from "../../API/getHouses";
import getCondos from "../../API/getCondos";
import getAccountsAtAddress from "../../API/getAccountsAtAddress";
import getAccountInfo from "../../API/getAccountInfo";
import getBalance from "../../API/getBalance";
import close from "../../Static/images/close.svg";
import mobClose from "../../Static/images/mobClose.svg";
import mobBack from "../../Static/images/mobBack.svg";
import { isBalance, isInfomat } from "../../API/URLS";

export default function SearchByaddress({
  setMethodOfReporting,
  clickHandler,
  currentInput,
  handlerSetCurrentInput,
  back,
  setData,
  setModalMdActive,
  setBalanceInfo,
  setBalanceWindowActive,
}) {
  const [district, setDistrict] = useState("");
  const [cities, setCities] = useState([]);
  const [currentCity, setCurrentCity] = useState();
  const [streets, setStreets] = useState([]);
  const [currentStreet, setCurrentStreet] = useState();
  const [houses, setHouses] = useState([]);
  const [currentHouse, setCurrentHouse] = useState();
  const [condos, setCondos] = useState([]);
  const [currentCondo, setCurrentCondo] = useState();
  const [lssList, setLssList] = useState([]);
  const [errorActive, setErrorActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLsNotFound, setModalLsNotFound] = useState(false);
  const [ModalChooseLsActive, setModalChooseLsActive] = useState(false);

  const [activeLs, setActiveLs] = useState("notSelected");
  const [currentLs, setCurrentLs] = useState();

  const [cityInputActive, setCityInputActive] = useState(false);
  const [streetInputActive, setStreetInputActive] = useState(false);
  const [houseInputActive, setHouseInputActive] = useState(false);
  const [condoInputActive, setCondoInputActive] = useState(false);

  const [inputChange, setInputChange] = useState(false);

  const cityItem = useRef();
  const streetItem = useRef();
  const houseItem = useRef();
  const condoItem = useRef();

  useEffect(() => {
    let request = true;
    let citiesTimer = null;
    let streetsTimer = null;
    let housesTimer = null;
    let condosTimer = null;

    cityItem.current.querySelector(`input`).dataset.error = false;
    streetItem.current.querySelector(`input`).dataset.error = false;
    houseItem.current.querySelector(`input`).dataset.error = false;
    setErrorActive(false);

    if (!ModalChooseLsActive) {
      setActiveLs("notSelected");
    }

    const apiLocalities = async () => {
      const data = await getLocalities(
        cityItem.current.querySelector(`input`).value
      );
      if (cityItem.current.querySelector(`input`).value.length >= 3) {
        if (request) {
          setCities([...data]);
        }
      }
    };

    const apiStreets = async () => {
      const data = await getStreets(
        currentCity.locality,
        streetItem.current.querySelector(`input`).value,
        currentCity.district,
        currentCity.district_type
      );
      if (streetItem.current.querySelector(`input`).value.length >= 3) {
        if (request) {
          setStreets([...data]);
        }
      }
    };

    const apiHouses = async () => {
      const data = await getHouses(
        currentCity?.locality,
        currentStreet?.street,
        currentCity?.district,
        currentCity?.district_type,
        currentStreet?.street_type,
        houseItem?.current?.querySelector(`input`).value
      );
      if (houseItem.current.querySelector(`input`).value.length > 0) {
        if (request) {
          setHouses([...data]);
        }
      }
    };

    const apiCondos = async () => {
      const data = await getCondos(
        currentHouse?.address_id,
        condoItem?.current?.querySelector(`input`).value
      );
      if (condoItem.current.querySelector(`input`).value.length > 0) {
        if (request) {
          setCondos([...data]);
        }
      }
    };

    if (currentInput?.parentNode === cityItem.current) {
      setCityInputActive(true);
      if (currentInput?.value.length >= 3) {
        citiesTimer = setTimeout(() => apiLocalities(), 500);
      } else if (currentInput?.value.length < 3) {
        setDistrict("");
        setCities(...[]);
      }
    } else if (currentInput?.parentNode === streetItem.current) {
      if (currentInput?.value.length >= 3) {
        streetsTimer = setTimeout(() => apiStreets(), 500);
      } else if (currentInput?.value.length < 3) {
        setStreets(...[]);
        setCurrentStreet("");
      }
    } else if (currentInput?.parentNode === houseItem.current) {
      if (currentInput?.value.length >= 1) {
        housesTimer = setTimeout(() => apiHouses(), 500);
      } else if (currentInput?.value.length < 1) {
        setHouses(...[]);
        setCurrentHouse("");
      }
    } else if (currentInput?.parentNode === condoItem.current) {
      setCondoInputActive(true);
      if (currentInput?.value.length >= 1) {
        condosTimer = setTimeout(() => apiCondos(), 500);
      } else if (currentInput?.value.length < 1) {
        setCondos(...[]);
        setCurrentCondo("");
      }
    }

    return () => {
      request = false;
      clearTimeout(citiesTimer);
      clearTimeout(streetsTimer);
      clearTimeout(housesTimer);
      clearTimeout(condosTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputChange, ModalChooseLsActive]);

  function changeInput() {
    setInputChange(!inputChange);
  }

  function inputsCheck(errors) {
    if (!cityItem?.current?.querySelector(`input`).value) {
      cityItem.current.querySelector(`input`).dataset.error = true;
      errors++;
    }
    if (!streetItem?.current?.querySelector(`input`).value) {
      streetItem.current.querySelector(`input`).dataset.error = true;
      errors++;
    }
    if (!houseItem?.current?.querySelector(`input`).value) {
      houseItem.current.querySelector(`input`).dataset.error = true;
      errors++;
    }
    if (errors > 0) {
      setErrorActive(true);
    }
    return errors;
  }

  function renderClue(data, ref, idx) {
    if (data?.locality) {
      return (
        <div
          key={idx}
          onClick={() => {
            if (data.locality !== "error") {
              clickClue(data, ref);
            }
          }}
          className={styles.clue__item}
        >
          {data.locality === "error"
            ? "По вашему запросу ничего не найдено"
            : reactStringReplace(
                data.locality,
                cityItem.current.querySelector(`input`).value,
                (match, i) => <b key={match + i}>{match}</b>
              )}
          <div className={styles.addressDis}>
            {reactStringReplace(
              data.district,
              cityItem.current.querySelector(`input`).value,
              (match, i) => (
                <b key={match + i}>{match}</b>
              )
            )}{" "}
            {data.district_type}
          </div>
        </div>
      );
    } else if (data?.street) {
      return (
        <div
          key={idx}
          onClick={() => {
            if (data.street !== "error") {
              clickClue(data, ref);
            }
          }}
          className={styles.clue__item}
        >
          {data.street === "error"
            ? "По вашему запросу ничего не найдено"
            : reactStringReplace(
                `${data.street_type} ${data.street}`,
                streetItem.current.querySelector(`input`).value,
                (match, i) => <b key={match + i}>{match}</b>
              )}
        </div>
      );
    } else if (data?.house) {
      return (
        <div
          key={idx}
          onClick={() => {
            if (data.house !== "error") {
              clickClue(data, ref);
            }
          }}
          className={styles.clue__item}
        >
          {data.house === "error"
            ? "По вашему запросу ничего не найдено"
            : reactStringReplace(
                data.building_type
                  ? `${data.house_type} ${data.house}, ${data.building_type} ${data.building}`
                  : `${data.house_type} ${data.house}`,
                houseItem.current.querySelector(`input`).value,
                (match, i) => <b key={match + i}>{match}</b>
              )}
        </div>
      );
    } else if (data?.contact_number) {
      return (
        <div
          key={idx}
          onClick={() => {
            if (data.contact_number !== "error") {
              clickClue(data, ref);
            }
          }}
          className={styles.clue__item}
        >
          {data.contact_number === "error"
            ? "По вашему запросу ничего не найдено"
            : reactStringReplace(
                data.contact_number,
                condoItem.current.querySelector(`input`).value,
                (match, i) => <b key={match + i}>{match}</b>
              )}
        </div>
      );
    }
  }

  function clickClue(data, ref) {
    if (ref === cityItem) {
      currentInput.value = data.locality;
      setCurrentCity(data);
      setDistrict(`${data.district} ${data.district_type}`);
      setCityInputActive(false);
      setStreetInputActive(true);
      streetItem.current.querySelector(`input`).focus();
    } else if (ref === streetItem) {
      currentInput.value = data.street;
      setCurrentStreet(data);
      setStreetInputActive(false);
      setHouseInputActive(true);
      houseItem.current.querySelector(`input`).focus();
    } else if (ref === houseItem) {
      currentInput.value = data.building_type
        ? `${data.house}, ${data.building_type} ${data.building}`
        : `${data.house}`;
      setCurrentHouse(data);
      setHouseInputActive(false);
      setCondoInputActive(true);
      condoItem.current.querySelector(`input`).focus();
    } else if (ref === condoItem) {
      currentInput.value = data.contact_number;
      setCondoInputActive(false);
      setCurrentCondo(data);
    }
  }

  function onClickInput(e) {
    if (e.target.parentNode === cityItem.current) {
      streetItem.current.querySelector(`input`).value = "";
      houseItem.current.querySelector(`input`).value = "";
      condoItem.current.querySelector(`input`).value = "";
      setCityInputActive(true);
      setStreetInputActive(false);
      setHouseInputActive(false);
      setCondoInputActive(false);
      setStreets([...[]]);
      setHouses([...[]]);
      setCondos([...[]]);
    }
    if (e.target.parentNode === streetItem.current) {
      houseItem.current.querySelector(`input`).value = "";
      condoItem.current.querySelector(`input`).value = "";
      setCityInputActive(false);
      setStreetInputActive(true);
      setHouseInputActive(false);
      setCondoInputActive(false);
      setHouses([...[]]);
      setCondos([...[]]);
    }
    if (e.target.parentNode === houseItem.current) {
      condoItem.current.querySelector(`input`).value = "";
      setCityInputActive(false);
      setStreetInputActive(false);
      setHouseInputActive(true);
      setCondoInputActive(false);
      setCondos([...[]]);
    }
    if (e.target.parentNode === condoItem.current) {
      setCityInputActive(false);
      setStreetInputActive(false);
      setHouseInputActive(false);
      setCondoInputActive(true);
    }
  }

  function searchLsWindow() {
    setModalLsNotFound(false);
    setMethodOfReporting("ls");
  }

  async function getLss() {
    Store.payment.resetPaymentInfo();
    let errors = 0;
    errors = inputsCheck(errors);
    if (errors === 0) {
      condoItem.current.querySelector(`input`).value = "";
      setLoading(true);
      const data = await getAccountsAtAddress(
        currentHouse?.address_id,
        currentCondo?.condo_id
      );
      if (data.length < 1) {
        setLoading(false);
        setModalLsNotFound(true);
      } else if (!data[0]?.error) {
        if (data.length === 1) {
          setLoading(false);
          searchLs(data[0]);
        } else {
          setLoading(false);
          setLssList([...data]);
          setModalChooseLsActive(true);
        }
      } else if (data[0]?.error) {
        setLoading(false);
        setModalLsNotFound(true);
      }
    }
  }

  async function searchLs(ls) {
    let data;
    if (ls) {
      data = await getAccountInfo(ls.id_PA);
      Store.payment.setAccounts(ls);
    } else {
      data = await getAccountInfo(currentLs.id_PA);
      Store.payment.setAccounts(currentLs);
    }
    Store.payment.setAccountInfo(data);
    if (data !== "error") {
      if (data.number !== "" && data.core_devices) {
        if (isBalance) {
          if (data.number !== "" && data.core_devices) {
            const balance = await getBalance(Store.payment.accounts.id_PA);
            setBalanceInfo(balance);
            setBalanceWindowActive(true);
            setModalChooseLsActive(false);
          } else {
            setModalChooseLsActive(false);
            setModalLsNotFound(true);
          }
        } else {
          setData(data);
          setModalMdActive(true);
          setModalChooseLsActive(false);
        }
      } else {
        setData(data);
        setModalMdActive(true);
        setModalChooseLsActive(false);
      }
    }
  }

  return (
    <div className={isInfomat ? `${styles.infomat}` : ""}>
      <div className={styles.title}>
        <MediaQuery minWidth={769}>
          <Heading level={"h1"}>
            {" "}
            {isBalance
              ? "Поиск лицевого счета"
              : "Передача показаний приборов учета"}
          </Heading>
        </MediaQuery>
      </div>
      <div className={styles.form}>
        <MediaQuery maxWidth={768}>
          <div
            onClick={() => {
              back();
            }}
            className={styles.back}
          >
            <img src={mobBack} alt="" />
            <Heading level={"h4"}>Передача показаний </Heading>
          </div>
        </MediaQuery>
        <div
          ref={cityItem}
          className={
            cityInputActive
              ? `${styles.item} ${styles.active}`
              : `${styles.item}`
          }
        >
          <div className={styles.title}>Населенный пункт/Город</div>
          <Input
            autofocus
            clickHandler={clickHandler}
            changeHandler={changeInput}
            onClick={(e) => onClickInput(e)}
          ></Input>
          <div className={styles.district}>{district}</div>
          <div className={styles.clue}>
            {cities?.map((city, idx) => {
              return renderClue(city, cityItem, idx);
            })}
          </div>
        </div>
        <div
          ref={streetItem}
          className={
            streetInputActive
              ? `${styles.item} ${styles.active}`
              : streetItem?.current?.querySelector(`input`).value
              ? `${styles.item}`
              : `${styles.item} ${styles.disabled}`
          }
        >
          <div className={styles.title}>Улица</div>
          <Input
            clickHandler={clickHandler}
            onClick={(e) => onClickInput(e)}
            changeHandler={changeInput}
          ></Input>
          <div className={`${styles.clue} ${styles.clue_streets}`}>
            {streets?.map((street, idx) => {
              return renderClue(street, streetItem, idx);
            })}
          </div>
        </div>
        <div className={styles.row}>
          <div
            ref={houseItem}
            className={
              houseInputActive
                ? `${styles.item} ${styles.active}`
                : houseItem?.current?.querySelector(`input`).value
                ? `${styles.item}`
                : `${styles.item} ${styles.disabled}`
            }
          >
            <div className={styles.title}>Дом/Корпус</div>
            <Input
              clickHandler={clickHandler}
              onClick={(e) => onClickInput(e)}
              changeHandler={changeInput}
            ></Input>
            <div className={`${styles.clue} ${styles.clue_houses}`}>
              {houses?.map((house, idx) => {
                return renderClue(house, houseItem, idx);
              })}
            </div>
          </div>
          <div
            ref={condoItem}
            className={
              condoInputActive
                ? `${styles.item} ${styles.active}`
                : condoItem?.current?.querySelector(`input`).value
                ? `${styles.item}`
                : `${styles.item} ${styles.disabled}`
            }
          >
            <div className={styles.title}>Квартира</div>
            <Input
              clickHandler={clickHandler}
              onClick={(e) => onClickInput(e)}
              changeHandler={changeInput}
            ></Input>
            <div className={`${styles.clue} ${styles.clue_houses}`}>
              {condos?.map((condo, idx) => {
                return renderClue(condo, condoItem, idx);
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonRow}>
        <div className={styles.buttonWrap}>
          <div
            onClick={getLss}
            className={
              loading
                ? `${styles.button} ${styles.loading}`
                : `${styles.button}`
            }
          >
            {loading ? <span></span> : "Поиск"}
          </div>
          <div
            className={
              errorActive
                ? `${styles.error} ${styles.active}`
                : `${styles.error}`
            }
          >
            Заполните все поля ввода данных
          </div>
        </div>
      </div>
      {modalLsNotFound && (
        <div
          className={styles.modalLsNotFound}
          onClick={() => setModalLsNotFound(false)}
        >
          <div
            className={styles.modalLsNotFound__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={styles.modalLsNotFound__wrap__close}
              onClick={() => setModalLsNotFound(false)}
            >
              <MediaQuery minWidth={769}>
                <img src={close} alt="" />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img src={mobClose} alt="" />
              </MediaQuery>
            </div>
            <div className={styles.modalLsNotFound__wrap__content}>
              <div className={styles.modalLsNotFound__wrap__content__title}>
                {currentCity?.locality} {currentStreet?.street_type}{" "}
                {currentStreet?.street} {currentHouse?.house_type}{" "}
                {currentHouse?.house} {currentHouse?.building_type}
                {currentHouse?.building}
                {currentCondo
                  ? currentCondo.condo_type
                    ? `, ${currentCondo?.condo_type} ${currentCondo?.contact_number}`
                    : `, кв. ${currentCondo?.contact_number}`
                  : ``}
              </div>
              <div className={styles.modalLsNotFound__wrap__content__subTitle}>
                Лицевой счет по указанному адресу не найден. <br />
                Проверьте правильность ввода данных или <br />
                передайте показания по номеру лицевого счета
              </div>
              <div className={styles.modalLsNotFound__wrap__content__btns}>
                <div
                  className={styles.modalLsNotFound__wrap__content__btns__btn}
                  onClick={() => searchLsWindow()}
                >
                  <Text size="btnText">Передать по № ЛС</Text>
                </div>
                <div
                  className={`${styles.modalLsNotFound__wrap__content__btns__btn} ${styles.gray}`}
                  onClick={() => setModalLsNotFound(false)}
                >
                  <Text size="btnText">Отменить</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ModalChooseLsActive && (
        <div
          className={styles.modalChooseLs}
          //onClick={() => setModalChooseLsActive(false)}
        >
          <div
            className={styles.modalChooseLs__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={styles.modalChooseLs__wrap__close}
              onClick={() => setModalChooseLsActive(false)}
            >
              <MediaQuery minWidth={769}>
                <img src={close} alt="" />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img src={mobClose} alt="" />
              </MediaQuery>
            </div>
            <div className={styles.modalChooseLs__wrap__content}>
              <div className={styles.modalChooseLs__wrap__content__title}>
                Выберите лицевой счет
              </div>
              <div
                className={
                  styles.modalChooseLs__wrap__content__personalAccounts
                }
              >
                {lssList?.map((ls, idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        activeLs === idx
                          ? `${styles.ls} ${styles.active}`
                          : `${styles.ls}`
                      }
                      onClick={() => {
                        setActiveLs(idx);
                        setCurrentLs(lssList[idx]);
                      }}
                    >
                      {/*<div className={styles.ls__checkbox} />*/}
                      <div className={styles.ls__content}>
                        <div className={styles.ls__content__organization}>
                          <div
                            className={styles.ls__content__organization__logo}
                          >
                            <img src={ls.logo_org} alt="" />
                          </div>
                          <div
                            className={styles.ls__content__organization__name}
                          >
                            {ls.title_org}
                          </div>
                        </div>
                        <div className={styles.ls__content__lsNumber}>
                          Лицевой счет № {ls.number}
                        </div>
                        <div className={styles.ls__content__lsOwner}>
                          {ls.first_name} {ls.father_name} {ls.last_name}.
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.modalChooseLs__wrap__content__btns}>
                <div
                  className={
                    activeLs !== "notSelected"
                      ? `${styles.modalChooseLs__wrap__content__btns__btn} ${styles.active}`
                      : `${styles.modalChooseLs__wrap__content__btns__btn}`
                  }
                  onClick={() => searchLs()}
                >
                  <Text size="btnText">
                    {isBalance ? "Поиск" : "Передать показания"}
                  </Text>
                </div>
                <div
                  className={`${styles.modalChooseLs__wrap__content__btns__btn} ${styles.gray}`}
                  onClick={() => setModalChooseLsActive(false)}
                >
                  <Text size="btnText">Отменить</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {modalMdActive && (
        <ModalMd
          data={accountInfo}
          modalMdActive={modalMdActive}
          setModalMdActive={setModalMdActive}
          setCurrentInput={handlerSetCurrentInput}
          setFocus={setFocusToCityInput}
        />
      )} */}
    </div>
  );
}
