import { useEffect, useRef, useState } from "react";
import MediaQuery from "react-responsive";
import style from "./MeterReading.module.scss";
import Heading from "../Typography/Heading/Heading";
import Text from "../Typography/Text/Text";
import Button from "../Button/Button";
import Input from "../Input/Input";
import submitReadings from "../../API/submitReadings";
import getBalance from "../../API/getBalance";
import close from "../../Static/images/close.svg";
import mobClose from "../../Static/images/mobClose.svg";
import sbp from "../../Static/images/sbp.png";
import accordionUp from "../../Static/images/accordionUp.svg";
import { isInfomat } from "../../API/URLS";
import Store from "../../Stores/Store";
import getQrForPayment from "../../API/getQrCodeForPayment";
import { inputFocusMessage } from "../../Utils/iframeMessenger";
import { canPay } from "../../Utils/Utils";

export default function MeterReading({
  meterDevice,
  setCurrentInput,
  setInitMR,
  initMR,
  idx,
}) {
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [inputChange, setInputChange] = useState(false);
  const [readingsAbove, setReadingsAbove] = useState(false);
  const [accordionActive, setAccordionActive] = useState(false);
  //const [modalWantToPayActive, setModalWantToPayActive] = useState(false);
  const [sumCheckboxActive, setSumCheckboxActive] = useState(false);
  const [sumInputValue, setSumInputValue] = useState("");
  const [paymentActive, setPaymentActive] = useState(false);
  const [sumForPayment, setSumForPayment] = useState(0);
  const [mailCheckboxActive, setMailCheckboxActive] = useState(false);
  const [mailInputValue, setMailInputValue] = useState("");
  const [restBalanceInput, setRestBalanceInput] = useState("");
  const [modalQrPayActive, setModalQrPayActive] = useState(false);
  const [qrImage, setQrImage] = useState();
  //const [qrLink, setQrLink] = useState("");

  const inputsRef = useRef([]);

  useEffect(() => {
    const tempInputs = [];
    inputsRef.current.forEach((input) => {
      if (!inputs.includes(input.firstChild)) {
        tempInputs.push(input.firstChild);
      }
    });
    setInputs([...tempInputs]);

    function HARDCODEtypeOfService() {
      if (
        Store.payment.accounts.id_org === 1 ||
        Store.payment.accounts.id_org === "1"
      ) {
        Store.payment.setService("Электроснабжение");
      } else if (
        Store.payment.accounts.id_org === 2 ||
        Store.payment.accounts.id_org === "2"
      ) {
        Store.payment.setService("Водоснабжение, водоотведение");
      }
    }
    HARDCODEtypeOfService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idx === 0) {
      setAccordionActive(true);
    }
  }, [idx]);

  // let tariffNames;
  const md = meterDevice;
  const indications = meterDevice.rates;

  let unit;
  //костыль
  if (indications[0].title_unit) {
    unit = indications[0].title_unit;
  } else {
    unit = "м³";
  }

  // if (indications.length === 1) {
  //   tariffNames = ["Сутки"];
  // } else if (indications.length === 2) {
  //   tariffNames = ["День", "Ночь"];
  // } else if (indications.length === 3) {
  //   tariffNames = ["Пик", "Полупик", "Ночь"];
  // }

  const errors = {
    verification:
      "Ваш прибор учета неисправен. Обратитесь в обслуживающее подразделение поставщика услуг",
    readingsLessInput: "Заполните все поля ввода данных",
    readingsLessBtn:
      "Не все поля заполнены, либо введенные показания меньше предыдущих",
  };

  function isVerificationPeriod() {
    if (!md.date_check) {
      return false;
    }
    const nowDate = new Date().toLocaleDateString("ru-RU", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const currentDate = nowDate.split(".").reverse().join("");
    const mdDateCheck = new Date(md.date_check_next)
      .toLocaleDateString("ru-RU")
      .split(".")
      .reverse()
      .join("");

    if (currentDate >= mdDateCheck) {
      return true;
    }
    return false;
  }

  function setDifference(e) {
    let dif = 0;

    if (e.target.value) {
      const parsedInputValue = parseInt(e.target.value);
      if (!isNaN(parsedInputValue)) {
        e.target.value = parsedInputValue;
      } else {
        e.target.value = "";
      }
    }
    dif = e.target.value - Math.round(e.target.dataset.reading);
    if (isNaN(e.target.value)) {
      e.target.dataset.difference = "Введите данные в числовом формате";
    } else {
      e.target.dataset.difference = dif;
    }
    if (dif < 0) {
      e.target.dataset.difference = "Ваши показания меньше предыдущих";
    }
    setInputChange(!inputChange);
  }

  async function sendMeterReadings(forcibly) {
    const rates = [];

    setError(...[]);
    const tempErrors = [];
    inputsRef.current.forEach((input, idx) => {
      rates.push({
        id_tariff: indications[idx].id_tariff,
        id_indication: indications[idx].id_indication,
        reading: parseInt(input.firstChild.value),
      });
      if (
        parseInt(input.firstChild.value) <
        parseInt(input.firstChild.dataset.reading)
      ) {
        input.firstChild.dataset.error = true;
        tempErrors.push({ id: idx });
      } else if (!input.firstChild.value) {
        input.firstChild.dataset.error = true;
        tempErrors.push({ id: idx });
      } else {
        input.firstChild.dataset.error = false;
      }
    });
    setError([...tempErrors]);

    if (!forcibly) {
      let countNomrlaInputs = 0;
      if (!tempErrors.length) {
        inputsRef.current.forEach((input) => {
          if (input.dataset.difference > 5000) {
            setReadingsAbove(true);
          } else {
            countNomrlaInputs++;
            if (countNomrlaInputs === inputsRef.current.length) {
              submit();
            }
          }
        });
      }
    } else {
      setReadingsAbove(false);
      submit();
    }

    async function submit() {
      setSuccess(true);
      if (!success) {
        setLoading(true);
        const response = await submitReadings(md.id_meter, rates);
        setLoading(!response);
        setSuccess(response);
        setInitMR(idx + 1);
        inputsRef.current.forEach((input) => {
          input.firstChild.dataset.disabled = true;
          input.firstChild.dataset.success = true;
        });
        calculatePaymentAmount(rates);
      }
    }
  }

  function modalClose() {
    setReadingsAbove(false);
  }

  async function submitForm(e) {
    e.preventDefault();
    await sendMeterReadings();
  }

  function clickToggleSum() {
    setSumCheckboxActive(!sumCheckboxActive);
  }

  function clickToggleMail() {
    setMailCheckboxActive(!mailCheckboxActive);
  }

  function sumInputChange(value) {
    let filteredValue;
    filteredValue = value
      .replace(/^[^\d]/g, "")
      .replace(/[^\d.]/g, "")
      .replace(/^([^.]*\.)|\./g, "$1");
    if (filteredValue.indexOf(".") !== -1) {
      filteredValue = filteredValue.substring(
        0,
        filteredValue.indexOf(".") + 3
      );
    }
    setSumInputValue(filteredValue);

    if (filteredValue) {
      if (sumForPayment - filteredValue * 100 <= 0) {
        setRestBalanceInput(0);
      } else {
        if (!isNaN(filteredValue)) {
          setRestBalanceInput(
            (sumForPayment - Math.round(filteredValue * 100)) / 100
          );
        } else {
          setRestBalanceInput("");
        }
      }
    } else {
      setRestBalanceInput("");
    }
  }

  async function calculatePaymentAmount(rates) {
    let balance = await getBalance(Store.payment.accounts.id_PA);
    if (balance !== "error") {
      balance = balance.amount1;
      let sum =
        balance +
        indications.reduce(
          (accumulator, item, idx) =>
            accumulator + item.cost * (rates[idx].reading - item.reading),
          0
        );
      if (sum <= 0) {
        setSumForPayment(0);
      } else {
        // Сумма для оплаты в копейках, округленная в большую сторону (3.544 р. -> 355 коп.)
        setSumForPayment(Math.ceil(parseFloat(sum).toFixed(3) * 100));
      }
      setPaymentActive(true);
    }
  }

  async function payment() {
    if (isInfomat) {
      if (sumCheckboxActive) {
        if (parseFloat(sumInputValue)) {
          Store.payment.setAmount(
            Math.round(parseFloat(sumInputValue).toFixed(3) * 100)
          );
          Store.payment.setPaymentWindowActive(true);
        } else {
          Store.payment.setAmount("");
        }
      } else {
        if (sumForPayment) {
          Store.payment.setAmount(sumForPayment);
          Store.payment.setPaymentWindowActive(true);
        }
      }
    } else if (!isInfomat) {
      if (mailCheckboxActive) {
        Store.payment.setEmail(mailInputValue);
      } else {
        Store.payment.setEmail("");
      }
      if (sumCheckboxActive) {
        if (parseFloat(sumInputValue)) {
          setPayLoading(true);
          Store.payment.setAmount(
            Math.round(parseFloat(sumInputValue).toFixed(3) * 100)
          );
          const data = await getQrForPayment(
            Store.payment.amount,
            Store.payment.accounts.id_PA,
            Store.payment.email,
            Store.payment.service
          );
          if (data) {
            setQrImage(data.data.data.image.content);
            //setQrLink(data.data.data.payload);
            setModalQrPayActive(true);
          }
        } else {
          Store.payment.setAmount("");
        }
      } else {
        if (sumForPayment) {
          setPayLoading(true);
          Store.payment.setAmount(sumForPayment);
          const data = await getQrForPayment(
            Store.payment.amount,
            Store.payment.accounts.id_PA,
            Store.payment.email,
            Store.payment.service
          );
          if (data) {
            setQrImage(data.data.data.image.content);
            //setQrLink(data.data.data.payload);
            setModalQrPayActive(true);
          }
        }
      }
      setPayLoading(false);
    }
  }

  //function clickQrCode() {
  //  const link = document.createElement("a");
  //  link.href = qrLink;
  //  link.setAttribute("target", "_blank");
  //  document.body.appendChild(link);
  //  link.click();
  //  link.parentNode.removeChild(link);
  //}

  return (
    <>
      <MediaQuery minWidth={769}>
        <div
          className={
            isInfomat ? `${style.wrap} ${style.infomat}` : `${style.wrap}`
          }
        >
          <form onSubmit={(e) => submitForm(e)}>
            <div className={style.wrap__title}>
              <div className={style.meterDevice}>
                <Heading level="h4">
                  ПУ: {md.device_title} - {md.modification} (№{md.serial_number}
                  )
                </Heading>
              </div>
              {md.type_locality && (
                <div className={style.mdAddress}>
                  <Text size="small">
                    {md.type_locality} {md.locality} {md.type_street}{" "}
                    {md.street} {md.type_house} {md.house} {md.type_building}{" "}
                    {md.building} {md.condos_number && "кв."} {md.condos_number}
                  </Text>
                </div>
              )}
              {md.date_check && (
                <div
                  className={
                    isVerificationPeriod()
                      ? `${style.verificationDate} ${style.mdError}`
                      : `${style.verificationDate}`
                  }
                >
                  <Text size="small">
                    {isVerificationPeriod()
                      ? `Срок поверки истек: ${new Date(
                          md.date_check_next
                        ).toLocaleDateString("ru-RU")}`
                      : `Дата очередной поверки: ${new Date(
                          md.date_check_next
                        ).toLocaleDateString("ru-RU")}`}
                  </Text>
                </div>
              )}
            </div>
            <div className={style.wrap__body}>
              {indications.map((el, idx) => (
                <div key={idx} className={style.meterReading}>
                  <div className={style.title}>
                    <Text size="medium" fw={500}>
                      {el.title}
                    </Text>
                  </div>
                  <div className={style.info}>
                    <div className={style.info__item}>
                      <Text size="meterReadingInfo">
                        Предыдущее показание:{" "}
                        <span>
                          {Math.round(el.reading)} {unit}
                        </span>
                      </Text>
                    </div>
                    <div className={style.info__item}>
                      <Text size="meterReadingInfo">
                        От:{" "}
                        <span>
                          {new Date(el.date_reading).toLocaleDateString(
                            "ru-RU"
                          )}
                        </span>
                      </Text>
                    </div>
                    {el.cost !== 0 ? (
                      <div className={style.info__item}>
                        <Text size="meterReadingInfo">
                          Тариф: <span>{el.cost}</span>
                        </Text>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    data-difference={inputs[idx]?.dataset.difference}
                    className={style.input}
                    ref={(el) => {
                      if (el && !inputsRef.current.includes(el)) {
                        inputsRef.current.push(el);
                      }
                    }}
                  >
                    <Input
                      clickHandler={setCurrentInput}
                      changeHandler={setDifference}
                      disabled={false}
                      autofocus={idx === 0 && initMR}
                      reading={el.reading}
                      placeholder={
                        el.current_month_reading_value &&
                        el.current_month_reading_date
                          ? `Учтено: ${
                              el.current_month_reading_value
                            } ${unit} от ${new Date(
                              el.current_month_reading_date
                            ).toLocaleDateString("ru-RU")}`
                          : ""
                      }
                      keyboardTypeNumber={true}
                    />
                    {!isVerificationPeriod() ? (
                      <div className={style.difference}>
                        {inputs[idx]?.dataset.difference >= 0 ? (
                          <Text size="meterReadingInfo">
                            Разница: {inputs[idx]?.dataset.difference} {unit}
                          </Text>
                        ) : (
                          <Text size="meterReadingInfo">
                            {inputs[idx]?.dataset.difference}
                          </Text>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className={style.button}>
              <Button disabled={false} success={success} loading={loading}>
                Отправить показания
                {/* {isVerificationPeriod() ? `Ошибка` : `Отправить показания`}  */}
              </Button>
            </div>
          </form>
          {isVerificationPeriod() && (
            <div className={style.error}>
              <Text size="small">{errors.verification}</Text>
            </div>
          )}
          {error.length > 0 && (
            <div className={style.error}>
              <Text size="small">{errors.readingsLessBtn}</Text>
            </div>
          )}
          {paymentActive && canPay() && (
            <div className={style.payment}>
              <div className={style.total}>
                <div className={style.total__text}>
                  <Text size="medium">Итого к оплате:</Text>
                </div>
                <div className={style.total__value}>
                  {sumForPayment / 100} ₽
                </div>
              </div>
              <div className={style.toggleWrap}>
                <Text size="medium">Указать сумму вручную</Text>
                <div className={style.checkbox}>
                  <input
                    type="checkbox"
                    className={style.toggle}
                    onClick={() => {
                      clickToggleSum();
                    }}
                    defaultChecked={false}
                  />
                </div>
              </div>
              {sumCheckboxActive && (
                <div className={style.sumInputs}>
                  <div className={style.enterValue}>
                    <div className={style.enterValue__title}>
                      <Text size="medium">Введите сумму платежа</Text>
                    </div>
                    <input
                      onChange={(e) => sumInputChange(e.target.value)}
                      value={sumInputValue}
                      className={style.enterValue__input}
                      autoFocus={true}
                      onFocus={() => inputFocusMessage("openNumKeyboard")}
                      onBlur={() => inputFocusMessage("closeKeyboard")}
                    />
                  </div>
                  <div className={style.enterValue}>
                    <div className={style.enterValue__title}>
                      <Text size="medium">Остаток</Text>
                    </div>
                    <input
                      value={restBalanceInput}
                      className={`${style.enterValue__input} ${style.disable}`}
                      readOnly
                    />
                  </div>
                </div>
              )}
              {!isInfomat && (
                <>
                  <div className={style.toggleWrap}>
                    <Text size="medium">
                      Прислать квитанцию об оплате на E-mail
                    </Text>
                    <div className={style.checkbox}>
                      <input
                        type="checkbox"
                        className={style.toggle}
                        onClick={() => {
                          clickToggleMail();
                        }}
                        defaultChecked={false}
                      />
                    </div>
                  </div>
                  {mailCheckboxActive && (
                    <div className={style.enterValue}>
                      <div className={style.enterValue__title}>
                        <Text size="medium">E-mail</Text>
                      </div>
                      <input
                        onChange={(e) => setMailInputValue(e.target.value)}
                        value={mailInputValue}
                        className={style.enterValue__input}
                        autoFocus={true}
                        onFocus={() => inputFocusMessage("openKeyboard")}
                        onBlur={() => inputFocusMessage("closeKeyboard")}
                        placeholder="Введите E-mail"
                      />
                    </div>
                  )}
                </>
              )}
              <div className={style.buttonPay}>
                <Button loading={payLoading} clickHandler={payment}>
                  Оплатить
                </Button>
              </div>
            </div>
          )}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        <div className={style.wrap}>
          <form onSubmit={(e) => submitForm(e)}>
            <div
              className={style.wrap__title}
              onClick={() => setAccordionActive(!accordionActive)}
            >
              <div className={style.meterDevice}>
                <Text size="medium" fw={500}>
                  ПУ: {md.device_title} - {md.modification} (№{md.serial_number}
                  )
                </Text>
              </div>
              {md.type_locality && (
                <div className={style.mdAddress}>
                  <Text size="small">
                    {md.type_locality} {md.locality} {md.type_street}{" "}
                    {md.street} {md.type_house} {md.house} {md.type_building}
                    {md.building} {md.condos_number && "кв."} {md.condos_number}
                  </Text>
                </div>
              )}
              {md.date_check && (
                <div
                  className={
                    isVerificationPeriod()
                      ? `${style.verificationDate} ${style.mdError}`
                      : `${style.verificationDate}`
                  }
                >
                  <Text size="small">
                    {isVerificationPeriod()
                      ? `Срок поверки истек: ${new Date(
                          md.date_check_next
                        ).toLocaleDateString("ru-RU")}`
                      : `Дата очередной поверки: ${new Date(
                          md.date_check_next
                        ).toLocaleDateString("ru-RU")}`}
                  </Text>
                </div>
              )}
              <div
                className={
                  accordionActive
                    ? `${style.accordionIcon} ${style.accordionVisible}`
                    : `${style.accordionIcon} ${style.accordionHidden}`
                }
              >
                <img src={accordionUp} alt="" />
              </div>
            </div>
            <div
              className={
                accordionActive
                  ? `${style.wrap__body} `
                  : `${style.wrap__body} ${style.accordionHidden}`
              }
            >
              {indications.map((el, idx) => (
                <div key={idx} className={style.meterReading}>
                  <div className={style.title}>
                    <Text size="medium">{el.title}</Text>
                  </div>
                  <div className={style.info}>
                    <div className={style.info__item}>
                      <Text size="meterReadingInfo">
                        Предыдущее показание:{" "}
                        <span>
                          {Math.round(el.reading)} {unit}
                        </span>
                      </Text>
                    </div>
                    <div className={style.info__item}>
                      <Text size="meterReadingInfo">
                        От:{" "}
                        <span>
                          {new Date(el.date_reading).toLocaleDateString(
                            "ru-RU"
                          )}
                        </span>
                      </Text>
                    </div>
                    {el.cost !== 0 ? (
                      <div className={style.info__item}>
                        <Text size="meterReadingInfo">
                          Тариф: <span>{el.cost}</span>
                        </Text>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    data-difference={inputs[idx]?.dataset.difference}
                    className={style.input}
                    ref={(el) => {
                      if (el && !inputsRef.current.includes(el)) {
                        inputsRef.current.push(el);
                      }
                    }}
                  >
                    <Input
                      clickHandler={setCurrentInput}
                      changeHandler={setDifference}
                      disabled={false}
                      autofocus={idx === 0 && initMR}
                      reading={el.reading}
                      placeholder={
                        el.current_month_reading_value &&
                        el.current_month_reading_date
                          ? `Учтено: ${
                              el.current_month_reading_value
                            } ${unit} от ${new Date(
                              el.current_month_reading_date
                            ).toLocaleDateString("ru-RU")}`
                          : ""
                      }
                    />
                    {!isVerificationPeriod() ? (
                      <div className={style.difference}>
                        {inputs[idx]?.dataset.difference >= 0 ? (
                          <Text size="meterReadingInfo">
                            Разница: {inputs[idx]?.dataset.difference} {unit}
                          </Text>
                        ) : (
                          <Text size="meterReadingInfo">
                            {inputs[idx]?.dataset.difference}
                          </Text>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
              <div className={`${style.button}`}>
                <Button disabled={false} success={success} loading={loading}>
                  Отправить показания
                </Button>
              </div>
              {isVerificationPeriod() && (
                <div className={`${style.error}`}>
                  <Text size="small">{errors.verification}</Text>
                </div>
              )}
              {error.length > 0 && (
                <div className={style.error}>
                  <Text size="small">{errors.readingsLessBtn}</Text>
                </div>
              )}
              {/*{paymentActive && canPay() && (
                <div className={style.payment}>
                  <div className={style.total}>
                    <div className={style.total__text}>
                      <Text size="medium">Итого к оплате:</Text>
                    </div>
                    <div className={style.total__value}>
                      {sumForPayment / 100} ₽
                    </div>
                  </div>
                  <div className={style.toggleWrap}>
                    <Text size="medium">Указать сумму вручную</Text>
                    <div className={style.checkbox}>
                      <input
                        type="checkbox"
                        className={style.toggle}
                        onClick={() => {
                          clickToggleSum();
                        }}
                        defaultChecked={false}
                      />
                    </div>
                  </div>
                  {sumCheckboxActive && (
                    <div className={style.sumInputs}>
                      <div className={style.enterValue}>
                        <div className={style.enterValue__title}>
                          <Text size="medium">Введите сумму платежа</Text>
                        </div>
                        <input
                          onChange={(e) => sumInputChange(e.target.value)}
                          value={sumInputValue}
                          className={style.enterValue__input}
                          autoFocus={true}
                          onFocus={() => inputFocusMessage("openNumKeyboard")}
                          onBlur={() => inputFocusMessage("closeKeyboard")}
                        />
                      </div>
                      <div className={style.enterValue}>
                        <div className={style.enterValue__title}>
                          <Text size="medium">Остаток</Text>
                        </div>
                        <input
                          value={restBalanceInput}
                          className={`${style.enterValue__input} ${style.disable}`}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  {!isInfomat && (
                    <>
                      <div className={style.toggleWrap}>
                        <Text size="medium">
                          Прислать квитанцию об оплате на E-mail
                        </Text>
                        <div className={style.checkbox}>
                          <input
                            type="checkbox"
                            className={style.toggle}
                            onClick={() => {
                              clickToggleMail();
                            }}
                            defaultChecked={false}
                          />
                        </div>
                      </div>
                      {mailCheckboxActive && (
                        <div className={style.enterValue}>
                          <div className={style.enterValue__title}>
                            <Text size="medium">E-mail</Text>
                          </div>
                          <input
                            onChange={(e) => setMailInputValue(e.target.value)}
                            value={mailInputValue}
                            className={style.enterValue__input}
                            autoFocus={true}
                            onFocus={() => inputFocusMessage("openKeyboard")}
                            onBlur={() => inputFocusMessage("closeKeyboard")}
                            placeholder="Введите E-mail"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={style.buttonPay}>
                    <Button loading={payLoading} clickHandler={payment}>
                      Оплатить
                    </Button>
                  </div>
                </div>
              )}*/}
            </div>
          </form>
        </div>
      </MediaQuery>
      {readingsAbove && (
        <div
          className={
            isInfomat
              ? `${style.readingsAbove} ${style.infomat}`
              : `${style.readingsAbove}`
          }
          onClick={() => modalClose()}
        >
          <div
            className={style.readingsAbove__wrap}
            //onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.readingsAbove__wrap__close}
              onClick={() => modalClose()}
            >
              <MediaQuery minWidth={769}>
                <img src={close} alt="" />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img src={mobClose} alt="" />
              </MediaQuery>
            </div>
            <div className={style.readingsAbove__wrap__content}>
              <div className={style.readingsAbove__wrap__content__title}>
                <Heading level="h1">
                  Ваши текущие показания <br />
                  превышают 5000 {unit}
                </Heading>
              </div>
              <div className={style.readingsAbove__wrap__content__btns}>
                <div
                  className={style.readingsAbove__wrap__content__btns__btn}
                  onClick={() => sendMeterReadings(true)}
                >
                  <Text size="btnText">
                    <MediaQuery minWidth={769}>Отправить показания</MediaQuery>
                    <MediaQuery maxWidth={768}>Отправить</MediaQuery>
                  </Text>
                </div>
                <div
                  className={`${style.readingsAbove__wrap__content__btns__btn} ${style.sec}`}
                  onClick={() => modalClose()}
                >
                  <Text size="btnText">Отменить</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalQrPayActive && (
        <div
          className={style.modalQrPay}
          onClick={() => setModalQrPayActive(false)}
        >
          <div
            className={style.modalQrPay__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.modalQrPay__wrap__close}
              onClick={() => setModalQrPayActive(false)}
            >
              <img src={close} alt="" />
            </div>
            <div className={style.modalQrPay__wrap__content}>
              <div className={style.modalQrPay__wrap__content__title}>
                <Heading level="h1">Система быстрых платежей</Heading>
              </div>
              <div className={style.modalQrPay__wrap__content__subtitle}>
                Наведите камеру смартфона для перехода в мобильное <br />
                приложение вашего банка для завершения оплаты
              </div>
              <div className={style.modalQrPay__wrap__content__sum}>
                <Heading level="h5">
                  Сумма к оплате {Store.payment.amount / 100} ₽
                </Heading>
              </div>
              <div className={style.modalQrPay__wrap__content__pay}>
                <div
                  className={style.modalQrPay__wrap__content__pay__qr}
                  //onClick={() => clickQrCode()}
                >
                  {qrImage && (
                    <img src={`data:image/jpeg;base64,${qrImage}`} alt="" />
                  )}
                </div>
                <div className={style.modalQrPay__wrap__content__pay__sbp}>
                  <img src={sbp} alt="" />
                </div>
              </div>
              <div
                className={style.modalQrPay__wrap__content__btn}
                onClick={() => setModalQrPayActive(false)}
              >
                <Text size="btnText">Отменить</Text>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*{modalWantToPayActive && (
        <div
          className={
            isInfomat
              ? `${style.modalWantToPay} ${style.infomat}`
              : `${style.modalWantToPay}`
          }
          onClick={() => setModalWantToPayActive(false)}
        >
          <div
            className={style.modalWantToPay__wrap}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={style.modalWantToPay__wrap__close}
              onClick={() => setModalWantToPayActive(false)}
            >
              <MediaQuery minWidth={769}>
                <img src={close} alt="" />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img src={mobClose} alt="" />
              </MediaQuery>
            </div>
            <div className={style.modalWantToPay__wrap__content}>
              <div className={style.modalWantToPay__wrap__content__title}>
                <Heading level="h1">
                  {isInfomat
                    ? "Желаете оплатить показания?"
                    : "Желаете оплатить переданные показания?"}
                </Heading>
              </div>
              <div className={style.modalWantToPay__wrap__content__btns}>
                <div
                  className={style.modalWantToPay__wrap__content__btns__btn}
                  onClick={() => wantToPay()}
                >
                  <Text size="btnText">
                    {isInfomat ? "Да" : "Да, оплатить по QR коду"}
                  </Text>
                </div>
                <div
                  className={`${style.modalWantToPay__wrap__content__btns__btn} ${style.sec}`}
                  onClick={() => setModalWantToPayActive(false)}
                >
                  <Text size="btnText">Нет</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}*/}
    </>
  );
}
