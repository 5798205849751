import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import style from "./ModalService.module.scss";
import Heading from "../Typography/Heading/Heading";
import close from "../../Static/images/close.svg";
import mobClose from "../../Static/images/mobClose.svg";

import { isInfomat } from "../../API/URLS";

export default function ModalService({
  modalServiceActive,
  setModalServiceActive,
  data,
  clickHandler,
  setFocus,
  currentInput,
}) {
  function modalClose() {
    setModalServiceActive(false);
    setFocus();
  }

  function keyPress(event) {
    if (event.code === "Escape") {
      modalClose(false);
      setFocus();
      window.removeEventListener("keydown", keyPress);
    }
  }

  useEffect(() => {
    currentInput.blur();
    window.addEventListener("keydown", keyPress);
  });

  return (
    <div
      className={
        modalServiceActive
          ? isInfomat
            ? `${style.modal} ${style.active}  ${style.infomat}`
            : `${style.modal} ${style.active}`
          : `${style.modal}`
      }
      onClick={() => modalClose()}
    >
      <div className={style.wrap}
      // onClick={(e) => e.stopPropagation()}
       >
        <div className={style.close}>
          <MediaQuery minWidth={769}>
            <img onClick={() => modalClose()} src={close} alt="" />
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <img onClick={() => modalClose()} src={mobClose} alt="" />
          </MediaQuery>
        </div>
        <div className={style.title}>
          <Heading level="h1">Ваш поставщик</Heading>
        </div>
        <div className={style.content}>
          {data.map((ls, idx) => (
            <div
              key={idx}
              className={style.button}
              onClick={() => clickHandler(ls.id_PA)}
            >
              <div className={style.button__logo}>
                <img src={ls.logo_org} alt="" />
              </div>
              <div className={style.button__organization}>{ls.title_org}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
