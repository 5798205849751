import axios from "axios";
import { URLS } from "./URLS";

export default async function getLocalities(text) {
  let data = [];
  await axios(` ${URLS.url}/${URLS.localitiesURL}?locality=${text}`)
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data.push({ locality: "error" });
    });
  return data;
}
