import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import style from "./ModalMd.module.scss";
import Heading from "../Typography/Heading/Heading";
import close from "../../Static/images/close.svg";
import mobClose from "../../Static/images/mobClose.svg";
import back from "../../Static/images/back_main.svg";
import mobBack from "../../Static/images/mobBack.svg";
import toMainImg from "../../Static/images/toMainImg.svg";
import MeterReading from "../MeterReading/MeterReading";
import Text from "../Typography/Text/Text";
import { isInfomat } from "../../API/URLS";
import { closeIframeMessage } from "../../Utils/iframeMessenger";

export default function ModalMd({
  modalMdActive,
  setModalMdActive,
  data,
  setCurrentInput,
  setFocus,
  currentInput,
}) {
  const [initMR, setInitMR] = useState(0);
  const [noCoreDevices, setNoCoreDevices] = useState(true);

  let ls = data.number;
  let coreDevices = data.core_devices;

  function modalClose() {
    setModalMdActive(false);
    setNoCoreDevices(false);
    window.removeEventListener("keydown", keyPress);
    //  setFocus();
  }

  function keyPress(event) {
    if (event.code === "Enter" || event.code === "Escape") {
      setModalMdActive(false);
      setFocus();
      window.removeEventListener("keydown", keyPress);
    }
  }

  useEffect(() => {
    if (!coreDevices?.length) {
      currentInput.blur();
      window.addEventListener("keydown", keyPress);
    }
  });

  if (coreDevices?.length) {
    coreDevices.sort(function (a, b) {
      let first = new Date(a.date_check_next);
      let second = new Date(b.date_check_next);
      return (
        second.toLocaleDateString("ru-RU").split(".").reverse().join("") -
        first.toLocaleDateString("ru-RU").split(".").reverse().join("")
      );
    });
  }

  return (
    <>
      {coreDevices?.length ? (
        <div
          className={
            modalMdActive &&
            (isInfomat ? `${style.modal} ${style.infomat}` : `${style.modal}`)
          }
        >
          <div
            className={style.wrap}
            //onClick={(e) => e.stopPropagation()}
          >
            <MediaQuery minWidth={769}>
              <div className={style.title}>
                <div className={style.back}>
                  <div
                    className={style.toMain}
                    onClick={() => closeIframeMessage()}
                  >
                    <img src={toMainImg} alt="" />
                    <Text size="medium" fw={500}>
                      Главная
                    </Text>
                  </div>
                  <div className={style.devider}>/</div>
                  <div
                    className={style.back__back}
                    onClick={() => modalClose(false)}
                  >
                    <img src={back} alt="" />
                    <Text size="medium" fw={500}>
                      Назад
                    </Text>
                  </div>
                </div>

                {/*<img onClick={() => modalClose(false)} src={back} alt="" />*/}
                <Heading level="h3">Лицевой счет №{ls}</Heading>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <div className={style.title}>
                <div className={style.mobBack}>
                  <img onClick={() => modalClose(false)} src={mobBack} alt="" />
                </div>
                <Heading level="h1">Лицевой счет №{ls}</Heading>
              </div>
            </MediaQuery>
            <div className={style.content}>
              {coreDevices?.map((meterDevice, idx) => (
                <MeterReading
                  key={idx}
                  meterDevice={meterDevice}
                  setCurrentInput={setCurrentInput}
                  initMR={initMR === idx}
                  setInitMR={setInitMR}
                  idx={idx}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        { noCoreDevices } && (
          <div
            className={
              isInfomat
                ? `${style.noCoreDevices} ${style.infomat}`
                : `${style.noCoreDevices}`
            }
          >
            <div
              className={style.noCoreDevices__wrap}
              //onClick={(e) => e.stopPropagation()}
            >
              <div
                className={style.noCoreDevices__wrap__close}
                onClick={() => modalClose(false)}
              >
                <MediaQuery minWidth={769}>
                  <img src={close} alt="" />
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                  <img src={mobClose} alt="" />
                </MediaQuery>
              </div>
              <div className={style.noCoreDevices__wrap__content}>
                {/* <div className={style.noCoreDevices__wrap__content__title}>
                  <Heading level="h1">Лицевой счет №{ls}</Heading>
                </div> */}
                <div className={style.noCoreDevices__wrap__content__subTitle}>
                  <MediaQuery minWidth={769}>
                    <Text size="medium" fw={500}>
                      К Вашему лицевому счету не подключен ни один прибор учета
                    </Text>
                  </MediaQuery>
                  <MediaQuery maxWidth={768}>
                    <Text size="medium">
                      К Вашему лицевому счету не подключен ни один прибор учета
                    </Text>
                  </MediaQuery>
                </div>
                <div className={style.noCoreDevices__wrap__content__btns}>
                  <div
                    className={style.noCoreDevices__wrap__content__btns__btn}
                    onClick={() => modalClose()}
                  >
                    <Text size="btnText">Завершить</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}
