import axios from "axios";
import { URLS, isInfomat } from "./URLS";

export default async function submitReadings(id_meter, rates) {
  function isOperator() {
    if (localStorage.getItem("isOperator") === "true") {
      return 56;
    } else if (isInfomat) {
      return 54;
    } else {
      return 42;
    }
  }
  const data = await axios
    .post(`${URLS.url}/${URLS.submitReadingsURL}`, {
      id_device: id_meter,
      id_receiving_method: isOperator(),
      rates: rates,
    })
    .catch(function (error) {});
  if (data.status === 200) {
    console.log("submit");
    return true;
  }
  console.log(data);
  return false;
}