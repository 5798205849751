import axios from "axios";
import { URLS } from "./URLS";

export default async function getStreets(locality, street, district, district_type) {
  let data = [];
  await axios(`${URLS.url}/${URLS.streetsURL}?locality=${locality}&street=${street}&district=${district}&district_type=${district_type}`)
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data.push({ street: "error" });
    });
  return data;
}


