import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Store from "./Stores/Store";
import MediaQuery from "react-responsive";
import "./App.scss";
import Text from "./Components/Typography/Text/Text";
import Heading from "./Components/Typography/Heading/Heading";
import Input from "./Components/Input/Input";
import ModalService from "./Components/ModalService/ModalService";
import ModalMd from "./Components/ModalMd/ModalMd";
import NotTime from "./Components/NotTime/NotTime";
import getAccounts from "./API/getAccounts";
import getAccountInfo from "./API/getAccountInfo";
import MethodOfReporting from "./Components/MethodOfReporting/MethodOfReporting";
import SearchByaddress from "./Components/SearchByAddress/SearchByAddress";
import logo from "./Static/images/logo.svg";
import userIcon from "./Static/images/user.svg";
import back from "./Static/images/back_main.svg";
import mobBack from "./Static/images/mobBack.svg";
import toMainImg from "./Static/images/toMainImg.svg";
import { isBalance, isInfomat } from "./API/URLS";
import PaymentWindow from "./Components/PaymentWIndow/PaymentWindow";
import BalanceWindow from "./Components/BalanceWindow/BalanceWindow";
import getBalance from "./API/getBalance";
import { closeIframeMessage, resetTimerMessage } from "./Utils/iframeMessenger";
// import googlePlay from "./Static/images/googlePlay.svg";
// import appStore from "./Static/images/appStore.svg";

window.onclick = () => {
  resetTimerMessage();
};

const App = observer(() => {
  const [errorActive, setErrorActive] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [modalServiceActive, setModalServiceActive] = useState(false);
  const [modalMdActive, setModalMdActive] = useState(false);
  const [data, setData] = useState();
  const [currentInput, setCurrentInput] = useState(null);
  const [isOperator, setIsOperator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [methodOfReporting, setMethodOfReporting] = useState("");
  const [modalLsClosed, setModalLsClosed] = useState(false);
  const [balanceWindowActive, setBalanceWindowActive] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState({});
  const [modalBalanceNoCoreDevicesActive, setModalBalanceNoCoreDevicesActive] =
    useState(false);

  const searchInput = useRef();

  useEffect(() => {
    if (isPeriod()) {
      setCurrentInput(searchInput?.current?.firstChild);
    }
  }, []);

  function isPeriod() {
    let nowDate;
    if (
      window.location.href.includes("backspark.ru") ||
      window.location.href.includes("localhost")
    ) {
      nowDate = 15;
    } else {
      nowDate = new Date().toLocaleDateString("ru-RU", {
        day: "2-digit",
      });
    }

    nowDate = parseInt(nowDate);

    if (nowDate < 15 || nowDate > 25) {
      if (isBalance) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    function checkIsOperator() {
      if (localStorage.getItem("isOperator") === "true") {
        setIsOperator(true);
      }
    }
    checkIsOperator();
  }, []);

  async function searchLs() {
    if (currentInput.value === "оператор") {
      localStorage.setItem("isOperator", !isOperator);
      if (localStorage.getItem("isOperator") === "true") {
        setIsOperator(true);
      } else if (localStorage.getItem("isOperator") === "false") {
        setIsOperator(false);
      }
    } else {
      if (currentInput.value) {
        setLoading(true);
        const data = await getAccounts(currentInput.value);
        setLoading(false);
        checkLsQuantity(data);
        setData(data);
      }
    }
  }

  async function checkLsQuantity(data) {
    setErrorActive(false);
    setServerError(false);
    if (!data[0]?.error) {
      if (data.length === 1) {
        const dataAccountInfo = await getAccountInfo(data[0].id_PA);
        setData(dataAccountInfo);
        if (dataAccountInfo.id_pa_status === 3) {
          setModalLsClosed(true);
        } else {
            Store.payment.resetPaymentInfo();
            Store.payment.setAccountInfo(dataAccountInfo);
            Store.payment.setAccounts(data[0]);
          if (isBalance) {
            if (dataAccountInfo.number !== "" && dataAccountInfo.core_devices) {
              setLoading(true);
              const balance = await getBalance(Store.payment.accounts.id_PA);
              setBalanceInfo(balance);
              setLoading(false);
              setBalanceWindowActive(true);
            } else if (!dataAccountInfo.core_devices) {
              setModalBalanceNoCoreDevicesActive(true);
            }
          } else {
            setModalMdActive(true);
          }
        }
      } else if (data.length > 1) {
        setModalServiceActive(true);
      } else {
        setErrorActive(true);
      }
    } else if (data[0]?.error) {
      setServerError(true);
    }
  }

  function setFocusToInitInput() {
    searchInput.current.firstChild.focus();
    searchInput.current.firstChild.value = "";
    setCurrentInput(searchInput.current.firstChild);
  }

  async function selectSupplier(id_PA) {
    setLoading(true);
    const dataAccountInfo = await getAccountInfo(id_PA);
    setLoading(false);
    setData(dataAccountInfo);
    setModalServiceActive(false);
    if (dataAccountInfo.id_pa_status === 3) {
      setModalLsClosed(true);
    } else {
      setModalMdActive(true);
    }
  }

  function handlerSetCurrentInput(e) {
    if (currentInput) {
      currentInput.dataset.active = false;
    }
    setCurrentInput(e.target);
    e.target.dataset.active = true;
    e.target.dataset.error = false;
  }

  async function submitForm(e) {
    e.preventDefault();
    await searchLs();
  }

  function lsError() {
    setErrorActive(false);
  }

  return (
    <div className="App">
      <div className={isInfomat ? "main infomat" : "main"}>
        {!isInfomat && (
          <div className="header">
            <div className="header__wrapper">
              <div className="header__content">
                <div className="header__content__logo">
                  <a href="https://ch-sk.ru/">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="header__content__info">
                  {/* <div className="header__content__info__item">
                  <a href="https://retail.ch-sk.ru/">
                    <Text size="headerText">Физическим лицам</Text>
                  </a>
                </div> */}
                  <div className="header__content__info__item">
                    <div className="hotline">
                      <a href="tel:+78002000742">
                        <Text size="headerText">Горячая линия</Text>
                      </a>
                    </div>
                    <div className="hotline-number">
                      <a href="tel:+78002000742">
                        <Text size="headerText">8-800-2000-742</Text>
                      </a>
                    </div>
                  </div>
                </div>
                <a href="https://lknew.ch-sk.ru/person-auth">
                  <div className="header__content__lk">
                    <div className="userIcon">
                      <img src={userIcon} alt="" />
                    </div>
                    <Text size="medium">Личный кабинет</Text>
                  </div>
                </a>
                <a href="https://lknew.ch-sk.ru/person-auth">
                  <div className="header__content__lkk">
                    <Text size="medium" fw={500}>
                      Войти
                    </Text>
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
        {!isPeriod() ? (
          <>
            {!methodOfReporting && (
              <MethodOfReporting setMethodOfReporting={setMethodOfReporting} />
            )}
            {methodOfReporting === "ls" && (
              <>
                <MediaQuery minWidth={769}>
                  <div className="content">
                    <div className="content__wrapper">
                      <div className="back">
                        <div
                          className="toMain"
                          onClick={() => closeIframeMessage()}
                        >
                          <img src={toMainImg} alt="" />
                          <Text size="medium" fw={500}>
                            Главная
                          </Text>
                        </div>
                        <div className="devider">/</div>
                        <div
                          className="back__back"
                          onClick={() => {
                            setMethodOfReporting("");
                            setErrorActive(false);
                          }}
                        >
                          <img src={back} alt="" />
                          <Text size="medium" fw={500}>
                            Назад
                          </Text>
                        </div>
                      </div>
                      <div className="search-form">
                        <form action="" onSubmit={(e) => submitForm(e)}>
                          <div className="search-form__header">
                            <Heading level="h1">
                              {isBalance
                                ? "Поиск лицевого счета"
                                : "Передача показаний приборов учета"}
                            </Heading>
                          </div>
                          <div className="search-form__title">
                            <Text size="medium" fw={600}>
                              Номер лицевого счета
                            </Text>
                          </div>
                          <div ref={searchInput} className="search-form__body">
                            <Input
                              clickHandler={handlerSetCurrentInput}
                              autofocus={true}
                              keyboardTypeNumber={true}
                            />
                            <button
                              className={
                                !loading
                                  ? "search-form__body__button"
                                  : "search-form__body__button loading"
                              }
                            >
                              {loading ? (
                                <span></span>
                              ) : (
                                <Text size="btnText">Поиск</Text>
                              )}
                            </button>
                          </div>
                          <div className="search-form__description">
                            {!errorActive && !serverError && (
                              <Text size="searchLsDescription">
                                Введите номер лицевого счета в том виде, в
                                котором он указан в вашей квитанции
                              </Text>
                            )}
                            {errorActive && (
                              <div className="error">
                                <Text size="searchLsDescription">
                                  Лицевой счет с таким номером не найден
                                </Text>
                              </div>
                            )}
                            {serverError && (
                              <div className="error">
                                <Text size="searchLsDescription">
                                  Ошибка сервера
                                </Text>
                              </div>
                            )}
                          </div>
                          {isInfomat && (
                            <button
                              className={
                                !loading
                                  ? "search-form__button"
                                  : "search-form__button loading"
                              }
                            >
                              {loading ? (
                                <span></span>
                              ) : (
                                <Text size="btnText">Поиск</Text>
                              )}
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                    {modalMdActive && (
                      <ModalMd
                        modalMdActive={modalMdActive}
                        setModalMdActive={setModalMdActive}
                        data={data}
                        setCurrentInput={handlerSetCurrentInput}
                        setFocus={setFocusToInitInput}
                        currentInput={currentInput}
                      ></ModalMd>
                    )}
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                  <div className="content">
                    <div className="content__wrapper">
                      <div className="search-form">
                        <form action="" onSubmit={(e) => submitForm(e)}>
                          <div
                            className="search-form__header"
                            onClick={() => {
                              setMethodOfReporting("");
                            }}
                          >
                            <img src={mobBack} alt="" />
                            <Heading level="h1">Передача показаний</Heading>
                          </div>
                          <div className="search-form__title">
                            <Text size="medium">№ Лицевого счета</Text>
                            <div className="search-form__title__error">
                              {errorActive && (
                                <div className="error">
                                  <Text size="searchLsDescription">
                                    Лицевой счет не найден
                                  </Text>
                                </div>
                              )}
                              {serverError && (
                                <div className="error">
                                  <Text size="searchLsDescription">
                                    Ошибка сервера
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                          <div ref={searchInput} className="search-form__body">
                            <Input
                              clickHandler={handlerSetCurrentInput}
                              autofocus={true}
                              error={errorActive ? true : false}
                              changeHandler={lsError}
                            />
                          </div>
                          <div className="search-form__description">
                            <Text size="searchLsDescription">
                              Введите номер лицевого счета в том виде, в котором
                              он указан в вашей квитанции
                            </Text>
                          </div>
                          <button
                            className={
                              !loading
                                ? "search-form__body__button"
                                : "search-form__body__button loading"
                            }
                          >
                            {loading ? (
                              <span></span>
                            ) : (
                              <Text size="btnText">Поиск</Text>
                            )}
                          </button>
                        </form>
                      </div>
                    </div>
                    {modalMdActive && (
                      <ModalMd
                        modalMdActive={modalMdActive}
                        setModalMdActive={setModalMdActive}
                        data={data}
                        setCurrentInput={handlerSetCurrentInput}
                        setFocus={setFocusToInitInput}
                        currentInput={currentInput}
                      ></ModalMd>
                    )}
                  </div>
                  <div className="footer">
                    <div className="footer__content">
                      {" "}
                      <div className="footer__content__hotline">
                        <a href="tel:+78002000742">
                          <Text size="small">Горячая линия</Text>
                          <Text size="small">8-800-2000-742</Text>
                        </a>
                      </div>
                    </div>
                  </div>
                </MediaQuery>
              </>
            )}
            {methodOfReporting === "address" && (
              <>
                <div className="content">
                  <div className="content__wrapper">
                    <div className="back methodOfReportingBack">
                      <div
                        className="toMain"
                        onClick={() => closeIframeMessage()}
                      >
                        <img src={toMainImg} alt="" />
                        <Text size="medium" fw={500}>
                          Главная
                        </Text>
                      </div>
                      <div className="devider">/</div>
                      <div
                        className="back__back"
                        onClick={() => {
                          setMethodOfReporting("");
                          setErrorActive(false);
                        }}
                      >
                        <img src={back} alt="" />
                        <Text size="medium" fw={500}>
                          Назад
                        </Text>
                      </div>
                    </div>

                    <div className="search-form address-form">
                      <SearchByaddress
                        setMethodOfReporting={setMethodOfReporting}
                        clickHandler={handlerSetCurrentInput}
                        currentInput={currentInput}
                        back={setMethodOfReporting}
                        setData={setData}
                        setModalMdActive={setModalMdActive}
                        setBalanceInfo={setBalanceInfo}
                        setBalanceWindowActive={setBalanceWindowActive}
                      />
                    </div>
                  </div>
                  {modalMdActive && (
                    <ModalMd
                      modalMdActive={modalMdActive}
                      setModalMdActive={setModalMdActive}
                      data={data}
                      setCurrentInput={handlerSetCurrentInput}
                      setFocus={setFocusToInitInput}
                      currentInput={currentInput}
                    ></ModalMd>
                  )}
                </div>
                <MediaQuery maxWidth={768}>
                  <div className="footer">
                    <div className="footer__content">
                      <div className="footer__content__hotline">
                        <a href="tel:+78002000742">
                          <Text size="small">Горячая линия</Text>
                          <Text size="small">8-800-2000-742</Text>
                        </a>
                      </div>
                    </div>
                  </div>
                </MediaQuery>
              </>
            )}
          </>
        ) : (
          <NotTime />
        )}
      </div>
      {modalServiceActive && (
        <ModalService
          modalServiceActive={modalServiceActive}
          setModalServiceActive={setModalServiceActive}
          data={data}
          clickHandler={selectSupplier}
          setFocus={setFocusToInitInput}
          currentInput={currentInput}
        />
      )}
      {modalLsClosed && (
        <div
          className={isInfomat ? "modalLsClosed infomat" : "modalLsClosed"}
          onClick={() => setModalLsClosed(false)}
        >
          <div
            className="modalLsClosed__wrap"
            //onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modalLsClosed__wrap__close"
              onClick={() => setModalLsClosed(false)}
            ></div>
            <div className="modalLsClosed__wrap__content">
              <div className="modalLsClosed__wrap__content__title">
                <Heading level="h1">Данный лицевой счет закрыт</Heading>
              </div>
              <div className="modalLsClosed__wrap__content__subTitle">
                Передача показаний недоступна
              </div>
              <div className="modalLsClosed__wrap__content__btns">
                <div
                  className="modalLsClosed__wrap__content__btns__btn"
                  onClick={() => {
                    setModalLsClosed(false);
                    setMethodOfReporting("");
                  }}
                >
                  <Text size="large">Завершить</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalBalanceNoCoreDevicesActive && (
        <div
          className={isInfomat ? "modalLsClosed infomat" : "modalLsClosed"}
          onClick={() => setModalBalanceNoCoreDevicesActive(false)}
        >
          <div
            className="modalLsClosed__wrap"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modalLsClosed__wrap__close"
              onClick={() => setModalBalanceNoCoreDevicesActive(false)}
            ></div>
            <div className="modalLsClosed__wrap__content">
              <div className="modalLsClosed__wrap__content__subTitle">
                К Вашему лицевому счету не подключен ни один прибор учета
              </div>
              <div className="modalLsClosed__wrap__content__btns">
                <div
                  className="modalLsClosed__wrap__content__btns__btn"
                  onClick={() => {
                    setModalBalanceNoCoreDevicesActive(false);
                  }}
                >
                  <Text size="large">Завершить</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {balanceWindowActive && (
        <BalanceWindow
          setBalanceWindowActive={setBalanceWindowActive}
          balanceInfo={balanceInfo}
        />
      )}
      {Store.payment.paymentWindowActive && <PaymentWindow />}
    </div>
  );
});

export default App;
