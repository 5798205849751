import React from "react";
import styles from "./Button.module.scss";
import Text from "./../Typography/Text/Text";

export default function Button({ disabled, loading, success, clickHandler, children }) {
  let classes = styles.button;

  if (!clickHandler) {
    clickHandler = () => {};
  }

  if(disabled) {
    classes += ` ${styles.disabled}`
  }

  if(loading) {
    classes += ` ${styles.loading}`
  }

  if(success) {
    children = 'Отправлено'
    classes += ` ${styles.success}`
  }

  return (
    <button
      onClick={(e) => {
        clickHandler();
      }}
      className={classes}
    >
      {loading ? <span></span> : <Text size="medium">{children}</Text> }
      
    </button>
  );
}
