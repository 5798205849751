import axios from "axios";
import { URLS } from "./URLS";

export default async function getHouses(
  locality,
  street,
  district,
  district_type,
  street_type,
  house
) {
  let data = [];
  await axios(
    `${URLS.url}/${URLS.housesURL}?locality=${locality}&street=${street}&district=${district}&district_type=${district_type}&street_type=${street_type}&house=${house}`
  )
    .then((res) => {
      data = res.data;
    })
    .catch(function (error) {
      data.push({ street: "error" });
    });
  return data;
}
