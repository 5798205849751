export const URLS = {
  url: `${process.env.REACT_APP_EXPO_PUBLIC_BACKEND_API_HOST}cabinet/terminal`,
  //url: `https://lk-api.backspark.ru/api/v0/cabinet/terminal`,
  accountsURL: "getAccounts",
  accountInfoURL: "getAccountInfo",
  submitReadingsURL: "submitReadings",
  localitiesURL: "localities",
  streetsURL: "streets",
  housesURL: "houses",
  condosURL: "condos",
  accountsAtAddressURL: "accountsAtAddres",
  krakenURL: `${process.env.REACT_APP_EXPO_PUBLIC_BACKEND_API_HOST_KRAKEN}`,
  //krakenURL: `https://lk-api-dev-krak.backspark.ru/api/v0/`,
};

export const isInfomat = isInfomatCheck();

export const isBalance = isBalanceCheck();

export const infomatURL = getInfomatURL();

function isInfomatCheck() {
  return window.location.href.includes("infomat");
}

function isBalanceCheck() {
  return window.location.href.includes("balance");
}

function getInfomatURL() {
  const paramUrl = new URL(document.location).searchParams.get("url");
  const paramQServerUrl = new URL(document.location).searchParams.get(
    "qServerUrl"
  );
  return `${paramUrl}&qServerUrl=${paramQServerUrl}`;
}

console.log(process.env.REACT_APP_EXPO_PUBLIC_BACKEND_API_HOST);

// function setMainURL() {
// 	if (
// 	  window.location.href.includes("backspark.ru") ||
// 	  window.location.href.includes("localhost")
// 	) {
// 	  return "https://lk-api.backspark.ru/api/v0/cabinet/terminal";
// 	} else {
// 	  return 'https://lk-api-pp.backspark.ru/api/v0/cabinet/terminal'
// 	}
//  }
