import axios from "axios";
import { URLS } from "./URLS";

export default async function getAccountsAtAddress(address_id, condo_id) {
  let data = [];
  await axios(
    ` ${URLS.url}/${URLS.accountsAtAddressURL}?address_id=${address_id}${
      condo_id ? `&condo_id=${condo_id}` : ""
    }`
  )
    .then((res) => {
      data = [...res.data];
    })
    .catch(function (error) {
      data.push({ error: "error" });
    });
  //console.log(data);
  return data;
}
