import React from "react";
import styles from "./Text.module.scss";

export default function Text({ size, children, fw }) {
  let classes;

  if (size === "large") {
    classes = styles.large;
  } else if (size === "medium") {
    classes = styles.medium;
  } else if (size === "small") {
    classes = styles.small;
  } else if (size === "meterReadingInfo") {
    classes = styles.meterReadingInfo;
  } else if (size === "btnText") {
    classes = styles.btnText;
  } else if (size === "headerText") {
    classes = styles.headerText;
  } else if (size === "searchLsDescription") {
    classes = styles.searchLsDescription;
  }

  if (fw === 500) {
    classes += ` ${styles.fw500}`;
  } else if (fw === 600) {
    classes += ` ${styles.fw600}`;
  }

  return <p className={classes}>{children}</p>;
}
