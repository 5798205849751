import React, { useEffect, useRef } from "react";
import styles from "./Input.module.scss";
import { isInfomat } from "../../API/URLS";
import { inputFocusMessage } from "../../Utils/iframeMessenger";

export default function Input({
  clickHandler,
  changeHandler,
  autofocus,
  disabled,
  error,
  reading,
  placeholder,
  onClick,
  readOnly,
  defaultValue,
  keyboardTypeNumber,
}) {
  let classes = styles.input;

  if (disabled) {
    classes += ` ${styles.disabled}`;
  }

  if (error) {
    classes += ` ${styles.error}`;
  }

  if (readOnly) {
    classes += ` ${styles.readOnly}`;
  }

  if (isInfomat) {
    classes += ` ${styles.infomat}`;
  }

  const inputRef = useRef();

  useEffect(() => {
    if (autofocus) {
      inputRef.current.parentNode.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  }, [autofocus]);

  function focus(e) {
    if (clickHandler) {
      clickHandler(e);
    }
    if (keyboardTypeNumber) {
      inputFocusMessage("openNumKeyboard");
    } else {
      inputFocusMessage("openKeyboard");
    }
  }

  return (
    <input
      spellCheck="false"
      placeholder={placeholder}
      ref={inputRef}
      className={classes}
      onFocus={(e) => focus(e)}
      onBlur={() => inputFocusMessage("closeKeyboard")}
      onChange={changeHandler}
      autoFocus={autofocus}
      data-reading={reading}
      data-disabled={false}
      data-difference={0}
      onClick={onClick}
      defaultValue={defaultValue}
    ></input>
  );
}
